import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSurveys, createSurvey, deleteSurvey, duplicateSurvey, testCreateAndFetchSurvey, getSurveyWithResponses } from './serverComm';
import { BarChart2, Settings, Eye, Link, Copy, Trash2, BarChart3, Link2 } from 'lucide-react';
import { getAuthInstance } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import copy from 'clipboard-copy';
import frame39 from './frame-39.svg';
import startWithTemplate from './start-with-temp.svg';
import pollPrevBg from './poll-prev-bg.svg';
import LoadingAnimation from './components/LoadingAnimation';
import Templates from './Templates';

const backgroundColors = [
  '#FFD2D5',  // Pink
  '#FFE8CE',  // Orange
  '#DCE1FF',  // Blue
  '#FFFEC1',  // Yellow
  '#D3FBF1',  // Mint
];

const BackgroundSVG = () => (
  <img 
    src={pollPrevBg}
    alt=""
    className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
    style={{ mixBlendMode: 'soft-light' }}
  />
);

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTestingAPI, setIsTestingAPI] = useState(false);
  const auth = getAuthInstance();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const testAPI = async () => {
    try {
      setIsTestingAPI(true);
      const result = await testCreateAndFetchSurvey();
      console.log('Test result:', result);
      await fetchSurveys(); // Refresh the surveys list
    } catch (error) {
      console.error('API test failed:', error);
    } finally {
      setIsTestingAPI(false);
    }
  };

  const fetchSurveys = async () => {
    if (!auth.currentUser) {
      console.log('No user found, skipping fetch');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      console.log('Fetching surveys for user:', auth.currentUser.uid);
      const fetchedSurveys = await getSurveys();
      
      if (!Array.isArray(fetchedSurveys)) {
        console.error('Fetched surveys is not an array:', fetchedSurveys);
        setSurveys([]);
        return;
      }

      // Fetch detailed survey data with responses for each survey
      const surveysWithResponses = await Promise.all(
        fetchedSurveys.map(async (survey) => {
          try {
            if (!survey || !survey.surveyId) return null; // Skip invalid surveys
            const detailedSurvey = await getSurveyWithResponses(survey.surveyId);
            return {
              ...survey,
              responses: detailedSurvey.responses || []
            };
          } catch (error) {
            console.error(`Error fetching responses for survey ${survey?.surveyId}:`, error);
            return null;
          }
        })
      );
      
      // Filter out null values and invalid surveys
      const validSurveys = surveysWithResponses.filter(survey => 
        survey && survey.surveyId && !survey.isDeleted
      );
      
      console.log('Setting surveys state with:', validSurveys);
      setSurveys(validSurveys);
    } catch (error) {
      console.error('Error fetching surveys:', error);
      setError('Failed to load surveys');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveys();
  }, [auth.currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenuId) {
        const isClickOutside = !event.target.closest('.settings-menu') && 
                              !event.target.closest('.settings-button');
        if (isClickOutside) {
          setOpenMenuId(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openMenuId]);

  const handleNewSurvey = async () => {
    try {
      setIsLoading(true);
      const response = await createSurvey();
      console.log('Created survey:', response);
      navigate(`/survey/${response.surveyId}`);
    } catch (error) {
      console.error('Error creating new survey:', error);
      setError('Failed to create survey');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewSurvey = (e, surveyId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    window.open(`/s/${surveyId}`, '_blank');
  };

  const handleViewAnalytics = (e, surveyId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    navigate(`/survey/${surveyId}/analytics`);
  };

  const handleDeleteClick = async (e, survey) => {
    e.stopPropagation();
    console.log('Survey to delete:', survey); // Debug log

    // If the survey has no surveyId, try to find it in the surveys array
    if (!survey.surveyId && !survey._id) {
      const foundSurvey = surveys.find(s => 
        s.title === survey.title && 
        s.createdAt === survey.createdAt
      );
      if (foundSurvey) {
        survey = foundSurvey;
      }
    }

    // Get the surveyId from any possible location
    const surveyId = survey.surveyId || survey._id || survey.id;
    
    if (!surveyId) {
      // If we still can't find an ID, try to delete by title and creation date
      console.error('No valid ID found for survey:', survey);
      try {
        // Force a refresh of the surveys list
        await fetchSurveys();
        // Try to find the survey again after refresh
        const refreshedSurvey = surveys.find(s => 
          s.title === survey.title && 
          s.createdAt === survey.createdAt
        );
        if (refreshedSurvey && (refreshedSurvey.surveyId || refreshedSurvey._id)) {
          setSurveyToDelete(refreshedSurvey);
          setDeleteModalOpen(true);
          setOpenMenuId(null);
          return;
        }
      } catch (error) {
        console.error('Error refreshing surveys:', error);
      }
      alert('Unable to delete survey: Invalid survey data');
      return;
    }
    
    setSurveyToDelete({ ...survey, surveyId });
    setDeleteModalOpen(true);
    setOpenMenuId(null);
  };

  const handleConfirmDelete = async () => {
    if (!surveyToDelete) return;
    
    const surveyId = surveyToDelete.surveyId || surveyToDelete._id;
    if (!surveyId) {
      console.error('No valid ID found for survey to delete:', surveyToDelete);
      return;
    }

    try {
      setDeletingId(surveyId);
      console.log('Attempting to delete survey:', surveyId);
      
      await deleteSurvey(surveyId);
      
      // Update local state immediately
      setSurveys(prevSurveys => 
        prevSurveys.filter(s => (s.surveyId || s._id) !== surveyId)
      );
      
      // Reset states
      setDeleteModalOpen(false);
      setSurveyToDelete(null);
      setDeletingId(null);
      setOpenMenuId(null);
      
    } catch (error) {
      console.error('Error deleting survey:', error);
      alert('Failed to delete survey. Please try again.');
    } finally {
      setDeletingId(null);
    }
  };

  const handleCopyLink = (e, surveyId) => {
    e.stopPropagation();
    const link = `${window.location.origin}/s/${surveyId}`;
    copy(link).then(() => {
      setCopiedId(surveyId);
      setTimeout(() => setCopiedId(null), 3000);
    }).catch(err => console.error('Failed to copy:', err));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSettingsClick = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId(current => current === surveyId ? null : surveyId);
  };

  const handleMenuMouseLeave = () => {
    setOpenMenuId(null);
  };

  const handleDuplicateSurvey = async (e, surveyId) => {
    e.stopPropagation();
    try {
      setIsDuplicating(true);
      const duplicatedSurvey = await duplicateSurvey(surveyId);
      setSurveys(prevSurveys => [duplicatedSurvey, ...prevSurveys]);
      setOpenMenuId(null);
    } catch (error) {
      console.error('Error duplicating survey:', error);
    } finally {
      setIsDuplicating(false);
    }
  };

  const getBackgroundColor = (index) => {
    return backgroundColors[index % backgroundColors.length];
  };

  const handleUpgradeClick = () => {
    console.log('Upgrade button clicked');
    navigate('/pricing');
  };

  const handleCardClick = (surveyId) => {
    setOpenMenuId(null);
    navigate(`/survey/${surveyId}`);
  };

  const renderEmptyState = () => (
    <>
      <div className="w-full sm:w-[70%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
        <div className="flex justify-between items-center mb-6 sm:mb-8">
          <h1 className="text-[18px] sm:text-[21px] leading-[24px] sm:leading-[31px] font-['HK_Nova'] font-medium">
            Welcome to PollBolt, {auth.currentUser?.displayName || 'Guest'}. ⚡⚡
          </h1>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mb-6 sm:mb-8">
          <div 
            onClick={handleNewSurvey}
            className="bg-[#FFFD63] p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] border border-transparent hover:border-[#eeeef0] cursor-pointer transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={frame39} 
              alt="New Survey" 
              className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
            />
            <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start from scratch
            </span>
          </div>

          <div 
            onClick={() => setIsTemplateModalOpen(true)}
            className="bg-white p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] border border-transparent hover:border-[#eeeef0] cursor-pointer transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={startWithTemplate} 
              alt="Template" 
              className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
            />
            <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start with Template
            </span>
          </div>
        </div>
      </div>

      <Templates 
        isOpen={isTemplateModalOpen} 
        onClose={() => setIsTemplateModalOpen(false)} 
      />
    </>
  );

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }

  if (!surveys.length) {
    return renderEmptyState();
  }

  return (
    <>
      {(isLoading || isDuplicating) && <LoadingAnimation />}
      
      <div className="w-full sm:w-[70%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
        <div className="flex justify-between items-center mb-6 sm:mb-8">
          <h1 className="text-[18px] sm:text-[21px] leading-[24px] sm:leading-[31px] font-['HK_Nova'] font-medium">
            Welcome to PollBolt, {auth.currentUser?.displayName || 'Guest'}. ⚡⚡
          </h1>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mb-6 sm:mb-8">
          <div 
            onClick={handleNewSurvey}
            className="bg-[#FFFD63] p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] border border-transparent hover:border-[#eeeef0] cursor-pointer transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={frame39} 
              alt="New Survey" 
              className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
            />
            <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start from scratch
            </span>
          </div>

          <div 
            onClick={() => setIsTemplateModalOpen(true)}
            className="bg-white p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)] hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] border border-transparent hover:border-[#eeeef0] cursor-pointer transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]"
          >
            <img 
              src={startWithTemplate} 
              alt="Template" 
              className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
            />
            <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
              Start with Template
            </span>
          </div>
        </div>

        <div className="mb-4 sm:mb-6">
          <h2 
            className="text-black text-[18px] sm:text-[21px] text-left font-['HK_Nova'] font-medium leading-[21px]"
          >
            My Poll's
          </h2>
        </div>

        <div className="w-full h-px bg-gray-200 mb-4 sm:mb-6"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {surveys.map((survey, index) => {
            console.log('Rendering survey card:', {
              surveyId: survey.surveyId,
              viewCount: survey.viewCount,
              fullSurvey: survey
            });
            
            return (
              <div 
                key={survey.surveyId}
                onClick={() => handleCardClick(survey.surveyId)}
                className="relative bg-white border border-[#e4e4e4] rounded-[15px] transition-all duration-200 hover:shadow-lg hover:-translate-y-1 cursor-pointer h-[280px] sm:h-[320px]"
              >
                <div 
                  className="absolute inset-x-2.5 sm:inset-x-3.5 top-2.5 sm:top-3.5 h-[180px] sm:h-[200px] rounded-[10px] overflow-visible border border-[#e4e4e4]"
                  style={{ backgroundColor: getBackgroundColor(index) }}
                >
                  <div className="relative w-full h-full">
                    <img 
                      src={pollPrevBg}
                      alt=""
                      className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
                      style={{ mixBlendMode: 'soft-light' }}
                    />

                    <div className="absolute top-3 sm:top-4 right-3 sm:right-4 z-20">
                      <div className="relative">
                        <button
                          onClick={(e) => handleSettingsClick(e, survey.surveyId)}
                          className="settings-button w-[38px] h-[38px] flex items-center justify-center hover:bg-black/10 rounded-[10px] transition-colors"
                        >
                          <svg 
                            className="w-5 h-5 text-black" 
                            viewBox="0 0 24 24" 
                            fill="currentColor"
                            stroke="none"
                          >
                            <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                          </svg>
                        </button>

                        {openMenuId === survey.surveyId && (
                          <div 
                            onClick={(e) => e.stopPropagation()}
                            className="settings-menu absolute w-[160px] bg-white/95 rounded-xl shadow-lg border border-gray-100 p-1.5 backdrop-blur-sm"
                            style={{ 
                              top: '100%',
                              right: '0px',
                              marginTop: '8px',
                              zIndex: 100
                            }}
                          >
                            <div className="py-0.5">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleViewAnalytics(e, survey.surveyId);
                                  setOpenMenuId(null);
                                }}
                                className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-all group"
                              >
                                <svg 
                                  className="w-4 h-4 mr-2.5 text-gray-400 group-hover:text-gray-600 transition-colors"
                                  viewBox="0 0 24 24" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  strokeWidth="2"
                                >
                                  <path d="M18 20V10M12 20V4M6 20v-6"/>
                                </svg>
                                <span className="font-medium">Analytics</span>
                              </button>

                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDuplicateSurvey(e, survey.surveyId);
                                  setOpenMenuId(null);
                                }}
                                className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-all group"
                              >
                                <svg 
                                  className="w-4 h-4 mr-2.5 text-gray-400 group-hover:text-gray-600 transition-colors"
                                  viewBox="0 0 24 24" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  strokeWidth="2"
                                >
                                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
                                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
                                </svg>
                                <span className="font-medium">Duplicate</span>
                              </button>

                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteClick(e, survey);
                                  setOpenMenuId(null);
                                }}
                                className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-all group"
                              >
                                <svg 
                                  className="w-4 h-4 mr-2.5 text-gray-400 group-hover:text-gray-600 transition-colors"
                                  viewBox="0 0 24 24" 
                                  fill="none" 
                                  stroke="currentColor" 
                                  strokeWidth="2"
                                >
                                  <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
                                </svg>
                                <span className="font-medium">Delete</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="absolute left-[20px] sm:left-[24px] bottom-[16px] sm:bottom-[20px]">
                      <h2 className="text-[16px] sm:text-[18px] font-['HK_Nova'] font-medium leading-[20px] sm:leading-[22px] tracking-[-0.93px] text-black mb-3 max-w-[260px] sm:max-w-[280px]">
                        {survey.title}
                      </h2>
                      <div className="flex items-center gap-2">
                        <div className={`
                          flex items-center gap-2 px-3 py-1.5 rounded-full
                          ${survey.isOpen ? 'bg-[#ECFDF3] text-[#027A48]' : 'bg-[#FFF1F3] text-[#C01048]'}
                        `}>
                          <div className={`
                            w-1.5 h-1.5 rounded-full
                            ${survey.isOpen ? 'bg-[#027A48]' : 'bg-[#C01048]'}
                          `}/>
                          <span className="text-sm font-medium">
                            {survey.isOpen ? 'Active' : 'Closed'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-[24px] sm:inset-x-[32px] bottom-[20px] sm:bottom-[24px] flex items-center justify-between">
                  <div>
                    <div className="text-sm font-medium text-[#7b7b7d] tracking-[-0.48px] mb-1">
                      Total responses
                    </div>
                    <div className="text-3xl font-bold text-black tracking-[-1.08px]">
                      {survey.responses?.length || 0}
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-2 text-[#7b7b7d]">
                      <Eye className="w-4 h-4" />
                      <span className="text-sm font-medium tracking-[-0.48px]">
                        {survey.viewCount || 0}
                      </span>
                    </div>
                    <button 
                      onClick={(e) => handleCopyLink(e, survey.surveyId)}
                      className="flex items-center text-sm font-medium text-[#7b7b7d] tracking-[-0.48px] hover:text-gray-900 transition-colors"
                    >
                      <Link2 className="w-4 h-4 mr-1.5" />
                      {copiedId === survey.surveyId ? "Copied!" : "Share"}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {deleteModalOpen && (
          <div 
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
            onClick={() => setDeleteModalOpen(false)}
          >
            <div 
              className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Close button */}
              <button 
                onClick={() => setDeleteModalOpen(false)}
                className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M18 6L6 18M6 6l12 12" />
                </svg>
              </button>

              {/* Content */}
              <div className="text-center mb-8">
                <h3 className="text-3xl font-medium mb-2 font-['HK_Nova']">Delete Poll</h3>
                <p className="text-gray-500">
                  Are you sure you want to delete "{surveyToDelete?.title}"?<br />
                  This action cannot be undone.
                </p>
              </div>

              {/* Buttons */}
              <div className="flex gap-3">
                <button
                  onClick={() => {
                    setDeleteModalOpen(false);
                    setSurveyToDelete(null);
                  }}
                  className="w-1/2 h-14 text-lg rounded-xl border border-gray-200 hover:bg-gray-50 transition-all font-medium"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  disabled={deletingId !== null}
                  className="w-1/2 h-14 text-lg rounded-xl bg-black text-white hover:bg-black/90 transition-all font-medium disabled:opacity-50"
                >
                  {deletingId === surveyToDelete?.surveyId ? 'Deleting...' : 'Delete Poll'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <Templates 
        isOpen={isTemplateModalOpen} 
        onClose={() => setIsTemplateModalOpen(false)} 
      />
    </>
  );
};

export default SurveyList;
