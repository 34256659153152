import React from 'react';
import MainNavigation from './MainNavigation';
import Footer from './Footer';

const PageWrapper = ({ children, openSignIn, isMobileMenuOpen, setIsMobileMenuOpen, bgColor = "bg-[#FFFD63]" }) => {
  return (
    <div className="min-h-screen w-full flex flex-col">
      <div className={`w-full ${bgColor}`}>
        <div className="max-w-[1920px] mx-auto px-4">
          <MainNavigation 
            openSignIn={openSignIn}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          <main className="max-w-[1600px] mx-auto px-4 py-16">
            {children}
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PageWrapper; 