import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPollbolt from '../logo-pollbolt-main.svg';

const Footer = () => {
  const navigate = useNavigate();

  const links = {
    product: [
      { name: 'Features', href: '/features' },
      { name: 'Plans', href: '/plans' },
      { name: 'Templates', href: '/templates' },
      { name: 'Security', href: '/security' }
    ],
    resources: [
      { name: 'Documentation', href: '/docs' },
      { name: 'API Reference', href: '/api' },
      { name: 'Help Center', href: '/help' },
      { name: 'Blog', href: '/blog' }
    ],
    company: [
      { name: 'About Us', href: '/about' },
      { name: 'Careers', href: '/careers' },
      { name: 'Contact', href: '/contact' },
      { name: 'Legal', href: '/legal' }
    ],
    social: [
      { name: 'Twitter', href: 'https://twitter.com' },
      { name: 'LinkedIn', href: 'https://linkedin.com' },
      { name: 'GitHub', href: 'https://github.com' }
    ]
  };

  return (
    <footer className="bg-black text-white">
      <div className="max-w-[1920px] mx-auto px-4">
        <div className="max-w-[1600px] mx-auto py-20">
          {/* Main Footer Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 mb-16">
            {/* Logo and Description */}
            <div className="lg:col-span-2">
              <img 
                src={LogoPollbolt} 
                alt="PollBolt Logo" 
                className="h-8 mb-6 brightness-0 invert" 
              />
              <p className="text-gray-400 max-w-sm">
                Create and share surveys in minutes. Get instant responses and powerful analytics.
              </p>
            </div>

            {/* Links Sections */}
            <div>
              <h3 className="font-['HK_Nova'] font-medium text-lg mb-4">Product</h3>
              <ul className="space-y-3">
                {links.product.map((link) => (
                  <li key={link.name}>
                    <a 
                      href={link.href} 
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="font-['HK_Nova'] font-medium text-lg mb-4">Resources</h3>
              <ul className="space-y-3">
                {links.resources.map((link) => (
                  <li key={link.name}>
                    <a 
                      href={link.href} 
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="font-['HK_Nova'] font-medium text-lg mb-4">Company</h3>
              <ul className="space-y-3">
                {links.company.map((link) => (
                  <li key={link.name}>
                    <a 
                      href={link.href} 
                      className="text-gray-400 hover:text-white transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="pt-8 border-t border-gray-800 flex flex-col sm:flex-row justify-between items-center gap-4">
            <div className="text-gray-400 text-sm">
              © 2024 PollBolt. All rights reserved.
            </div>
            <div className="flex gap-6">
              {links.social.map((link) => (
                <a 
                  key={link.name}
                  href={link.href}
                  className="text-gray-400 hover:text-white transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 