import React from 'react';
import { useNavigate } from 'react-router-dom';
import LogoPollbolt from '../logo-pollbolt-main.svg';

const MainNavigation = ({ openSignIn, isMobileMenuOpen, setIsMobileMenuOpen }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* Header */}
      <header className="flex justify-between items-center p-4 sm:p-8">
        <div className="flex items-center gap-12">
          <img 
            src={LogoPollbolt} 
            alt="PollBolt Logo" 
            className="h-6 sm:h-8 cursor-pointer" 
            onClick={() => navigate('/')}
          />
          <nav className="hidden sm:flex items-center gap-6 pt-2">
            <button 
              className="text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors py-2"
              onClick={() => navigate('/features')}
            >
              Features
            </button>
            <button 
              className="text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors py-2"
              onClick={() => navigate('/plans')}
            >
              Plans
            </button>
          </nav>
        </div>

        {/* Mobile Menu Button */}
        <div className="sm:hidden">
          <button 
            className="text-black p-2"
            onClick={() => setIsMobileMenuOpen(prev => !prev)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Desktop Auth Buttons */}
        <div className="hidden sm:flex items-center gap-2">
          <button 
            className="px-4 sm:px-6 py-2 text-sm sm:text-base text-black hover:text-gray-700 transition-colors font-['HK_Nova'] font-medium"
            onClick={() => openSignIn(false)}
          >
            Login
          </button>
          <button 
            className="px-4 sm:px-6 py-2 text-sm sm:text-base rounded-xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova']"
            onClick={() => openSignIn(true)}
          >
            Sign up
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="sm:hidden absolute top-20 left-0 right-0 bg-white z-50 border-b border-gray-200">
          <nav className="flex flex-col p-4">
            <button 
              className="py-3 text-left text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors"
              onClick={() => {
                navigate('/features');
                setIsMobileMenuOpen(false);
              }}
            >
              Features
            </button>
            <button 
              className="py-3 text-left text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors"
              onClick={() => {
                navigate('/plans');
                setIsMobileMenuOpen(false);
              }}
            >
              Plans
            </button>
            <button 
              className="py-3 text-left text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors"
              onClick={() => {
                openSignIn(false);
                setIsMobileMenuOpen(false);
              }}
            >
              Login
            </button>
            <button 
              className="py-3 text-left text-base font-['HK_Nova'] font-medium text-black hover:text-gray-700 transition-colors"
              onClick={() => {
                openSignIn(true);
                setIsMobileMenuOpen(false);
              }}
            >
              Sign up
            </button>
          </nav>
        </div>
      )}
    </>
  );
};

export default MainNavigation; 