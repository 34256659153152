import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from '../assets/loading-animation.json';

const LoadingAnimation = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-80 z-50">
      <div className="w-40 h-40">
        <Lottie
          animationData={loadingAnimation}
          loop={true}
        />
      </div>
    </div>
  );
};

export default LoadingAnimation;
