import React, { useState } from 'react';
import PageWrapper from '../components/PageWrapper';
import { 
  Paintbrush, 
  LayoutTemplate, 
  GitBranch, 
  Share2, 
  Zap, 
  Globe, 
  BarChart3, 
  Brain, 
  FileOutput 
} from 'lucide-react';

const iconColors = {
  "Create & Design": [
    "bg-[#fffec0]", // Drag & Drop Builder
    "bg-[#ffe8ce]", // Smart Templates
    "bg-[#dce1fe]"  // Advanced Logic
  ],
  "Share & Collect": [
    "bg-[#ffd2d5]", // Multi-Channel Distribution
    "bg-[#fffec0]", // Real-Time Responses
    "bg-[#ffe8ce]"  // Offline Mode
  ],
  "Analyze & Act": [
    "bg-[#dce1fe]", // Visual Analytics
    "bg-[#ffd2d5]", // AI-Powered Insights
    "bg-[#fffec0]"  // Export & Share
  ]
};

const FeaturesPage = ({ openSignIn }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const features = [
    {
      category: "Create & Design",
      tagline: "Build beautiful surveys in minutes",
      description: "Create professional surveys with our intuitive tools and templates",
      items: [
        {
          title: "Drag & Drop Builder",
          description: "Build surveys effortlessly with our intuitive drag-and-drop interface. No technical skills required.",
          Icon: Paintbrush
        },
        {
          title: "Smart Templates",
          description: "Get started quickly with professionally designed templates for any use case.",
          Icon: LayoutTemplate
        },
        {
          title: "Advanced Logic",
          description: "Create dynamic surveys that adapt based on responses, ensuring relevant questions for each participant.",
          Icon: GitBranch
        }
      ]
    },
    {
      category: "Share & Collect",
      tagline: "Reach your audience everywhere",
      description: "Multiple ways to distribute your surveys and collect responses",
      items: [
        {
          title: "Multi-Channel Distribution",
          description: "Share via email, SMS, QR codes, or embed directly on your website.",
          Icon: Share2
        },
        {
          title: "Real-Time Responses",
          description: "Watch responses flow in real-time with instant notifications and live updates.",
          Icon: Zap
        },
        {
          title: "Offline Mode",
          description: "Collect responses without internet connection - perfect for field surveys.",
          Icon: Globe
        }
      ]
    },
    {
      category: "Analyze & Act",
      tagline: "Turn responses into insights",
      description: "Powerful analytics tools to make data-driven decisions",
      items: [
        {
          title: "Visual Analytics",
          description: "Beautiful charts and graphs that make your data easy to understand and present.",
          Icon: BarChart3
        },
        {
          title: "AI-Powered Insights",
          description: "Get automated insights and trends from your survey responses.",
          Icon: Brain
        },
        {
          title: "Export & Share",
          description: "Export your data in multiple formats or share interactive reports with your team.",
          Icon: FileOutput
        }
      ]
    }
  ];

  return (
    <PageWrapper
      openSignIn={openSignIn}
      isMobileMenuOpen={isMobileMenuOpen}
      setIsMobileMenuOpen={setIsMobileMenuOpen}
      bgColor="bg-[#d3fbee]"
    >
      {/* Hero Section */}
      <div className="text-center mb-32">
        <div className="inline-flex items-center bg-white/30 backdrop-blur-sm rounded-full px-4 py-2 mb-8">
          <span className="text-sm font-medium text-gray-700">Powerful Features</span>
          <span className="ml-2 bg-black text-white rounded-full px-3 py-1 text-xs font-semibold">New</span>
        </div>
        <h1 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl md:text-[72px] leading-tight tracking-[-2px] mb-8 text-gray-800 max-w-[800px] mx-auto">
          Everything you need to create amazing surveys
        </h1>
        <p className="font-['HK_Nova'] text-xl sm:text-2xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
          Powerful features wrapped in a simple interface, designed to help you get responses faster
        </p>
      </div>

      {/* Features Grid */}
      <div className="space-y-32">
        {features.map((category, index) => (
          <div key={index} className="relative">
            {/* Category Header */}
            <div className="text-center max-w-3xl mx-auto mb-16">
              <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-4 py-2 mb-6">
                <span className="text-sm font-medium text-gray-700">{category.tagline}</span>
              </div>
              <h2 className="text-4xl sm:text-5xl font-['HK_Nova'] font-bold mb-6 text-gray-800">
                {category.category}
              </h2>
              <p className="text-xl text-gray-600">
                {category.description}
              </p>
            </div>
            
            {/* Feature Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {category.items.map((feature, featureIndex) => (
                <FeatureCard 
                  key={featureIndex}
                  {...feature}
                  index={featureIndex}
                  bgColor={iconColors[category.category][featureIndex]}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* CTA Section */}
      <div className="text-center mt-32">
        <div className="bg-white/40 backdrop-blur-sm rounded-3xl p-12 sm:p-20 shadow-md hover:shadow-lg transition-shadow">
          <h2 className="text-3xl sm:text-4xl font-['HK_Nova'] font-bold mb-6 text-gray-800">
            Ready to create your first survey?
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Join thousands of users who are already creating better surveys with PollBolt
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
            <button 
              className="px-8 py-4 text-lg rounded-xl bg-black text-white hover:bg-gray-800 transition-colors font-['HK_Nova'] w-full sm:w-auto"
              onClick={openSignIn}
            >
              Get Started Free
            </button>
            <button 
              className="px-8 py-4 text-lg rounded-xl border-2 border-black hover:bg-black hover:text-white transition-colors font-['HK_Nova'] w-full sm:w-auto"
              onClick={() => window.location.href = '/plans'}
            >
              View Pricing
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

const FeatureCard = React.memo(({ title, description, Icon, index, bgColor }) => (
  <div 
    className="group bg-white/90 backdrop-blur-sm p-8 rounded-2xl shadow-md hover:shadow-lg transition-shadow"
    style={{
      opacity: 0,
      animation: `fadeIn 0.5s ease-out forwards ${index * 100}ms`
    }}
  >
    <div className={`mb-4 ${bgColor} w-12 h-12 rounded-xl flex items-center justify-center`}>
      <Icon className="w-6 h-6 text-gray-700" />
    </div>
    <h3 className="text-xl font-bold mb-3 font-['HK_Nova'] text-gray-800">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
));

// Update animation keyframes to be simpler
const style = document.createElement('style');
style.textContent = `
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
`;
document.head.appendChild(style);

export default FeaturesPage; 