import { initializeApp } from "firebase/app";
import { getAuth as getFirebaseAuth } from "firebase/auth";
import { 
  getStorage as getFirebaseStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCIUDcL-SK_5bAm0t4KkfnWcln18n8yrfg",
  authDomain: "surveyappreact.firebaseapp.com",
  projectId: "surveyappreact",
  storageBucket: "surveyappreact.firebasestorage.app",
  messagingSenderId: "366662516624",
  appId: "1:366662516624:web:15257e6d56c2c56ee83d15"
};

let app;
let auth;
let storage;

// Initialize Firebase if it hasn't been initialized yet
try {
  app = initializeApp(firebaseConfig);
} catch (error) {
  if (!/already exists/.test(error.message)) {
    console.error('Firebase initialization error', error.stack);
  }
}

// Get Auth instance
export const getAuthInstance = () => {
  if (!auth) {
    auth = getFirebaseAuth(app);
  }
  return auth;
};

// Get Storage instance
export const getStorageInstance = () => {
  if (!storage) {
    storage = getFirebaseStorage(app);
  }
  return storage;
};

// Export storage related functions
export {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll
};

// Export the app instance
export { app };

