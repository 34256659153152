import React, { useState, useEffect, useRef, useCallback, useMemo, memo, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSurvey, checkResponse, submitResponse, recordSurveyView } from './serverComm';
import QuestionRenderer from './QuestionRenderer';
import LoadingAnimation from './components/LoadingAnimation';
import { ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const SCROLL_THRESHOLD = 50;
const SCROLL_DEBOUNCE = 100;
const LOCK_DURATION = 800;

const Logo = memo(({ styles }) => {
  const logoClasses = useMemo(() => `
    ${styles?.logo?.size === 'sm' ? 'max-h-[30px]' : 
      styles?.logo?.size === 'md' ? 'max-h-[50px]' : 'max-h-[70px]'}
    ${styles?.logo?.alignment === 'left' ? 'ml-4 mr-auto' : 
      styles?.logo?.alignment === 'center' ? 'mx-auto' : 'ml-auto mr-4'}
    object-contain
  `, [styles?.logo?.size, styles?.logo?.alignment]);

  if (!styles?.logo?.url) return null;
  
  return (
    <div className="fixed top-12 left-0 right-0 w-full z-50">
      <img
        src={styles.logo.url}
        alt=""
        className={logoClasses}
        loading="eager"
      />
    </div>
  );
}, (prev, next) => {
  return prev.styles?.logo?.url === next.styles?.logo?.url &&
         prev.styles?.logo?.size === next.styles?.logo?.size &&
         prev.styles?.logo?.alignment === next.styles?.logo?.alignment;
});

const Background = memo(({ styles }) => {
  const backgroundStyle = useMemo(() => ({
    backgroundImage: styles?.backgroundImage ? `url(${styles.backgroundImage})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  }), [styles?.backgroundImage]);

  if (!styles?.backgroundImage) return null;

  return (
    <>
      <div 
        className="absolute inset-0 z-0 md:hidden"
        style={backgroundStyle}
      />
      <div className="hidden md:block">
        {styles.backgroundAlignment === 'cover' ? (
          <div 
            className="absolute inset-0 z-0"
            style={backgroundStyle}
          />
        ) : (
          <div 
            className={`absolute top-0 h-full w-1/2 overflow-hidden ${
              styles.backgroundAlignment === 'left' ? 'left-0' : 'right-0'
            }`}
          >
            <img
              src={styles.backgroundImage}
              alt=""
              className="w-full h-full object-cover"
              loading="eager"
            />
          </div>
        )}
      </div>
    </>
  );
}, (prev, next) => {
  return prev.styles?.backgroundImage === next.styles?.backgroundImage &&
         prev.styles?.backgroundAlignment === next.styles?.backgroundAlignment;
});

const NavigationButtons = memo(({ 
  currentIndex, 
  totalQuestions, 
  onNavigate 
}) => (
  <div className="fixed right-8 top-1/2 -translate-y-1/2 flex-col gap-4 hidden md:flex">
    <motion.button
      onClick={() => onNavigate('prev')}
      disabled={currentIndex === 0}
      whileHover={{ scale: 1.1, opacity: 1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className={`w-12 h-12 rounded-full flex items-center justify-center
        ${currentIndex === 0 
          ? 'opacity-30 cursor-not-allowed' 
          : 'opacity-60'
        }
        bg-white shadow-lg`}
    >
      <ChevronUp size={24} />
    </motion.button>
    
    <motion.button
      onClick={() => onNavigate('next')}
      disabled={currentIndex === totalQuestions - 1}
      whileHover={{ scale: 1.1, opacity: 1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className={`w-12 h-12 rounded-full flex items-center justify-center
        ${currentIndex === totalQuestions - 1
          ? 'opacity-30 cursor-not-allowed' 
          : 'opacity-60'
        }
        bg-white shadow-lg`}
    >
      <ChevronDown size={24} />
    </motion.button>
  </div>
));

const ThankYouAnimation = lazy(() => import('./components/ThankYouAnimation'));

const MobileNavigationButtons = memo(({ 
  currentIndex, 
  totalQuestions, 
  onNavigate,
  styles,
  isSubmitting
}) => (
  <div className="fixed bottom-6 left-0 right-0 px-4 md:hidden z-50">
    <div className="flex items-center gap-2">
      {/* Previous Button - Only show if not on first question */}
      {currentIndex > 0 && (
        <button
          type="button"
          onClick={() => onNavigate('prev')}
          disabled={isSubmitting}
          className="h-[52px] w-[52px] flex-shrink-0 rounded-xl bg-white/90 backdrop-blur 
                   text-gray-600 font-medium shadow-sm border border-gray-100
                   flex items-center justify-center
                   transition-all transform active:scale-98
                   disabled:opacity-70 disabled:cursor-not-allowed"
        >
          <ChevronLeft size={24} />
        </button>
      )}

      {/* Next/Submit Button */}
      <button
        type="button"
        onClick={() => onNavigate('next')}
        disabled={isSubmitting}
        className={`
          flex-1 h-[52px] py-4 px-6 rounded-xl text-white font-medium
          flex items-center justify-center gap-2
          transition-all transform active:scale-98
          ${currentIndex === totalQuestions - 1 ? '' : 'shadow-lg'}
          ${isSubmitting ? 'opacity-70 cursor-not-allowed' : ''}
        `}
        style={{ 
          backgroundColor: styles?.submitButtonColor || '#4F46E5',
        }}
      >
        <span>
          {currentIndex === totalQuestions - 1 
            ? (isSubmitting ? 'Submitting...' : 'Submit')
            : 'Next'
          }
        </span>
        {currentIndex !== totalQuestions - 1 && <ChevronRight size={20} />}
      </button>
    </div>
  </div>
));

const SurveyViewer = () => {
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { surveyId } = useParams();
  const [hasResponded, setHasResponded] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const scrollTimeout = useRef(null);
  const [navigationDirection, setNavigationDirection] = useState('next');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState(null);

  // Optimize scroll handler
  const handleScroll = useCallback((event) => {
    event.preventDefault();
    
    if (isScrollLocked || isSubmitting) return;

    const scrollValue = Math.abs(event.deltaY);
    if (scrollValue < SCROLL_THRESHOLD) return;

    // Determine scroll direction
    const direction = event.deltaY > 0 ? 'next' : 'prev';
    
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = setTimeout(() => {
      const canNavigate = direction === 'next' 
        ? currentQuestionIndex < (survey?.questions?.length - 1)
        : currentQuestionIndex > 0;

      if (canNavigate) {
        setNavigationDirection(direction);
        setCurrentQuestionIndex(prev => direction === 'next' ? prev + 1 : prev - 1);
        setIsScrollLocked(true);
        
        // Reset scroll lock after animation completes
        setTimeout(() => {
          setIsScrollLocked(false);
        }, LOCK_DURATION);
      }
    }, SCROLL_DEBOUNCE);
  }, [isScrollLocked, currentQuestionIndex, survey?.questions?.length, isSubmitting]);

  // Memoize handlers
  const handleAnswerChange = useCallback((questionId, answer) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
  }, []);

  const handleNext = useCallback(() => {
    if (survey && currentQuestionIndex < survey.questions.length - 1) {
      setNavigationDirection('next');
      setCurrentQuestionIndex(prev => prev + 1);
    }
  }, [survey, currentQuestionIndex]);

  const handlePrevious = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setNavigationDirection('prev');
      setCurrentQuestionIndex(prev => prev - 1);
    }
  }, [currentQuestionIndex]);

  // Memoize current question data
  const currentQuestion = useMemo(() => {
    return survey?.questions?.[currentQuestionIndex];
  }, [survey?.questions, currentQuestionIndex]);

  // Memoize current answer
  const currentAnswer = useMemo(() => {
    return currentQuestion ? answers[currentQuestion.id] : null;
  }, [currentQuestion, answers]);

  // Update the renderQuestion function to use Framer Motion
  const renderQuestion = useCallback(() => {
    if (!currentQuestion) return null;

    const variants = {
      enter: (direction) => ({
        y: direction === 'next' ? 100 : -100,
        opacity: 0
      }),
      center: {
        y: 0,
        opacity: 1
      },
      exit: (direction) => ({
        y: direction === 'next' ? -100 : 100,
        opacity: 0
      })
    };

    return (
      <AnimatePresence mode="wait" custom={navigationDirection}>
        <motion.div
          key={currentQuestionIndex}
          custom={navigationDirection}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            y: { type: "tween", duration: 0.4, ease: "easeOut" },
            opacity: { duration: 0.3 }
          }}
          className="w-full"
        >
          <QuestionRenderer
            question={currentQuestion}
            answer={currentAnswer}
            onAnswerChange={(answer) => 
              handleAnswerChange(currentQuestion.id, answer)
            }
            onNavigate={(direction) => direction === 'next' ? handleNext() : handlePrevious()}
            isLastQuestion={currentQuestionIndex === survey.questions.length - 1}
            styles={{
              ...survey.styles,
              questionTextSize: 'text-3xl',
              questionTextWeight: 'font-medium',
              questionTextLineHeight: 'leading-relaxed',
            }}
            isDesignerPreview={false}
            navigationDirection={navigationDirection}
            questionNumber={currentQuestionIndex + 1}
            totalQuestions={survey.questions.length}
          />
        </motion.div>
      </AnimatePresence>
    );
  }, [currentQuestion, currentAnswer, navigationDirection, currentQuestionIndex, survey?.questions?.length, survey?.styles]);

  // Update the ProgressBar component to use Framer Motion
  const ProgressBar = memo(({ progress }) => (
    <div className="w-full bg-gray-50 h-[3px]">
      <motion.div
        className="bg-gradient-to-r from-blue-400 to-blue-500 h-full"
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ duration: 0.3, ease: "easeOut" }}
      />
    </div>
  ));

  // Attach scroll listener
  useEffect(() => {
    let lastScrollTime = Date.now();
    
    const handleWheel = (event) => {
      const now = Date.now();
      
      // Prevent too frequent scroll events
      if (now - lastScrollTime < SCROLL_DEBOUNCE) {
        event.preventDefault();
        return;
      }
      
      lastScrollTime = now;
      handleScroll(event);
    };

    // Add options for better trackpad handling
    const options = { 
      passive: false,
      capture: true
    };

    window.addEventListener('wheel', handleWheel, options);
    
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
      window.removeEventListener('wheel', handleWheel, options);
    };
  }, [handleScroll]);

  const fetchSurveyData = async () => {
    try {
      setLoading(true);
      const response = await fetchSurvey(surveyId);
      console.log('Fetched survey data:', response);
      console.log('Background image URL:', response.styles?.backgroundImage);
      setSurvey(response);
    } catch (error) {
      console.error('Error fetching survey:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const checkPreviousResponse = () => {
      const responseKey = `survey_${surveyId}_responded`;
      const hasRespondedBefore = localStorage.getItem(responseKey);
      if (hasRespondedBefore) {
        setHasResponded(true);
      }
    };

    if (surveyId) {
      checkPreviousResponse();
      fetchSurveyData();
    }
  }, [surveyId]);

  useEffect(() => {
    const recordView = async () => {
      if (surveyId) {
        try {
          console.log('Recording view for survey:', surveyId);
          const result = await recordSurveyView(surveyId);
          console.log('View recording result:', result);
          
          if (result.success) {
            console.log('Successfully recorded view. New count:', result.viewCount);
          } else {
            console.log('Failed to record view:', result.message);
          }
        } catch (error) {
          console.error('Error recording view:', error);
        }
      }
    };

    recordView();
  }, [surveyId]);

  useEffect(() => {
    if (survey && !startTime) {
      setStartTime(Date.now());
    }
  }, [survey]);

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      const completionTime = Math.floor((Date.now() - startTime) / 1000);
      await submitResponse(surveyId, answers, completionTime);
      localStorage.setItem(`survey_${surveyId}_responded`, 'true');
      setHasResponded(true);
    } catch (error) {
      console.error('Error submitting response:', error);
    } finally {
      setIsSubmitting(false);
    }
  }, [surveyId, answers, isSubmitting, startTime]);

  const handleNavigationClick = useCallback((direction) => {
    console.log('Navigation clicked:', direction, 'Current index:', currentQuestionIndex);

    if (direction === 'next') {
      // Handle submission if it's the last question
      if (currentQuestionIndex === survey?.questions?.length - 1) {
        handleSubmit();
        return;
      }
      
      // Otherwise move to next question
      setNavigationDirection('next');
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      // Handle previous navigation
      if (currentQuestionIndex > 0) {
        setNavigationDirection('prev');
        setCurrentQuestionIndex(prev => prev - 1);
      }
    }
  }, [currentQuestionIndex, survey?.questions?.length, handleSubmit]);

  useEffect(() => {
    const startTime = performance.now();
    
    return () => {
      const endTime = performance.now();
      console.log(`Component render time: ${endTime - startTime}ms`);
    };
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className="text-center py-10 text-red-500">{error}</div>
    );
  }

  if (!survey) {
    return (
      <div className="text-center py-10">Survey not found.</div>
    );
  }

  if (!survey || !survey.questions || survey.questions.length === 0) {
    return (
      <div className="text-center py-10">No questions found in this survey.</div>
    );
  }

  if (hasResponded) {
    return (
      <div 
        className="min-h-screen flex items-center justify-center relative"
        style={{ 
          backgroundColor: survey.styles?.backgroundColor || '#FFFFFF',
          fontFamily: survey.styles?.fontFamily || 'Inter, sans-serif',
        }}
      >
        <Background styles={survey?.styles} />
        <Logo styles={survey?.styles} />
        
        {/* Content Container */}
        <div className={`relative z-10 ${
          survey.styles?.backgroundImage && survey.styles?.backgroundAlignment !== 'cover'
            ? 'w-full md:w-1/2 ' + (survey.styles?.backgroundAlignment === 'right' ? 'md:mr-auto' : 'md:ml-auto')
            : 'w-full'
        }`}>
          <div className="flex flex-col items-center justify-center p-8">
            <Suspense fallback={<div className="w-64 h-64 mb-6" />}>
              <ThankYouAnimation />
            </Suspense>
            <div 
              className="text-center text-2xl"
              style={{ 
                fontFamily: survey.styles?.fontFamily || 'inherit',
                color: survey.styles?.questionTextColor || '#1F2937'
              }}
            >
              Thank you for your response!
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-container">
      <Logo styles={survey?.styles} />
      <Background styles={survey?.styles} />
      <NavigationButtons 
        currentIndex={currentQuestionIndex}
        totalQuestions={survey?.questions?.length || 0}
        onNavigate={handleNavigationClick}
      />
      <div 
        className="min-h-screen relative"
        style={{ 
          backgroundColor: survey.styles?.backgroundColor || '#FFFFFF',
          fontFamily: survey.styles?.fontFamily || 'Inter, sans-serif',
        }}
      >
        {/* Background Image Container */}
        {survey.styles?.backgroundImage && (
          <>
            {/* Mobile: Always cover */}
            <div 
              className="absolute inset-0 z-0 md:hidden"
              style={{
                backgroundImage: `url(${survey.styles.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            
            {/* Desktop: Follow user preference */}
            <div className="hidden md:block">
              {survey.styles.backgroundAlignment === 'cover' ? (
                <div 
                  className="absolute inset-0 z-0"
                  style={{
                    backgroundImage: `url(${survey.styles.backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              ) : (
                <div 
                  className={`absolute top-0 h-full w-1/2 overflow-hidden ${
                    survey.styles.backgroundAlignment === 'left' ? 'left-0' : 'right-0'
                  }`}
                >
                  <img
                    src={survey.styles.backgroundImage}
                    alt="Background"
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
          </>
        )}

        {/* Main Content Wrapper */}
        <div className={`relative z-10 min-h-screen flex flex-col ${
          survey.styles?.backgroundImage && survey.styles?.backgroundAlignment !== 'cover'
            ? 'w-full md:w-1/2 ' + (survey.styles?.backgroundAlignment === 'right' ? 'md:mr-auto' : 'md:ml-auto')
            : 'w-full'
        }`}>
          {/* Progress bar */}
          <div className="sticky top-0 left-0 right-0 z-10">
            <div className="w-full bg-gray-50 h-[3px]">
              <div
                className="bg-gradient-to-r from-blue-400 to-blue-500 h-full transition-all duration-300 ease-out"
                style={{ width: `${(currentQuestionIndex / (survey.questions.length - 1)) * 100}%` }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 flex items-start md:items-center justify-center">
            <div className="w-full max-w-3xl mx-auto px-4 py-4 md:py-8 mt-24 md:mt-0">
              <div 
                id="survey-container" 
                className="relative"
                style={{ touchAction: 'none' }}
              >
                <AnimatePresence mode="wait" initial={false} custom={navigationDirection}>
                  {renderQuestion()}
                </AnimatePresence>
              </div>
            </div>
          </div>

          {/* Navigation buttons */}
          <div className="fixed right-8 top-1/2 -translate-y-1/2 flex-col gap-4 hidden md:flex">
            <button
              onClick={() => handleNavigationClick('prev')}
              disabled={currentQuestionIndex === 0}
              className={`w-12 h-12 rounded-full flex items-center justify-center transition-all
                ${currentQuestionIndex === 0 
                  ? 'opacity-30 cursor-not-allowed' 
                  : 'opacity-60 hover:opacity-100 hover:scale-110'
                }
                bg-white shadow-lg`}
            >
              <ChevronUp size={24} />
            </button>
            
            <button
              onClick={() => handleNavigationClick('next')}
              disabled={currentQuestionIndex === survey.questions.length - 1}
              className={`w-12 h-12 rounded-full flex items-center justify-center transition-all
                ${currentQuestionIndex === survey.questions.length - 1
                  ? 'opacity-30 cursor-not-allowed' 
                  : 'opacity-60 hover:opacity-100 hover:scale-110'
                }
                bg-white shadow-lg`}
            >
              <ChevronDown size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Add the mobile navigation buttons */}
      <MobileNavigationButtons 
        currentIndex={currentQuestionIndex}
        totalQuestions={survey?.questions?.length || 0}
        onNavigate={handleNavigationClick}
        styles={survey?.styles}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default SurveyViewer;
