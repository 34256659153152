import { getAuthInstance } from './firebase';
import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { getStorageInstance, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from './firebase';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://surv-server.vercel.app';

const auth = getAuthInstance();

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const getAuthToken = async () => {
  let user = await getCurrentUser();

  if (!user) {
    await signInAnonymously(auth);
    user = await getCurrentUser();
  }

  try {
    return await user.getIdToken();
  } catch (error) {
    console.error('Error getting auth token:', error);
    throw new Error('Failed to get authentication token');
  }
};

const getAuthHeader = async () => {
  const token = await getAuthToken();
  return { Authorization: `Bearer ${token}` };
};

const apiCall = async (method, endpoint, data = null) => {
  try {
    const headers = await getAuthHeader();
    headers['Content-Type'] = 'application/json';

    const url = `${API_BASE_URL}${endpoint}`;
    const options = {
      method,
      headers,
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`API call failed: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('API call error:', error);
    throw error;
  }
};

export const get = (endpoint) => apiCall('GET', endpoint);
export const post = (endpoint, data) => apiCall('POST', endpoint, data);
export const del = (endpoint) => apiCall('DELETE', endpoint);

export const createSurvey = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const token = await user.getIdToken();
    
    const response = await fetch(`${API_BASE_URL}/api/createSurvey`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: 'Untitled Survey',
        questions: [],
        styles: {
          fontFamily: 'Inter',
          submitButtonColor: '#4F46E5',
          backgroundColor: '#FFFFFF',
          useCustomSubmitText: false,
          customSubmitText: ''
        }
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating survey:', error);
    throw error;
  }
};

export const saveSurvey = async (surveyData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const token = await user.getIdToken();
    
    const surveyId = surveyData.surveyId || surveyData._id;
    if (!surveyId) {
      throw new Error('No valid surveyId found in survey data');
    }

    // Ensure complete styles object with backgroundAlignment
    const styles = {
      ...surveyData.styles,
      fontFamily: surveyData.styles?.fontFamily || 'Inter',
      submitButtonColor: surveyData.styles?.submitButtonColor || '#4F46E5',
      backgroundColor: surveyData.styles?.backgroundColor || '#FFFFFF',
      questionTextColor: surveyData.styles?.questionTextColor || '#000000',
      optionTextColor: surveyData.styles?.optionTextColor || '#4B5563',
      useCustomSubmitText: surveyData.styles?.useCustomSubmitText || false,
      customSubmitText: surveyData.styles?.customSubmitText || '',
      backgroundImage: surveyData.styles?.backgroundImage || '',
      backgroundAlignment: surveyData.styles?.backgroundAlignment || 'cover',
      logo: {
        url: surveyData.styles?.logo?.url || '',
        size: surveyData.styles?.logo?.size || 'md',
        alignment: surveyData.styles?.logo?.alignment || 'left'
      }
    };

    console.log('Saving survey with styles:', styles);

    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId,
        title: surveyData.title,
        questions: surveyData.questions,
        styles
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log('Save survey response:', result);
    return result;
  } catch (error) {
    console.error('Error saving survey:', error);
    throw error;
  }
};

export const getSurveys = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) {
      console.warn('No authenticated user found');
      return [];
    }

    const token = await user.getIdToken();
    if (!token) {
      throw new Error('Failed to get authentication token');
    }

    console.log('Making request to:', `${API_BASE_URL}/api/surveys`);

    const response = await fetch(`${API_BASE_URL}/api/surveys`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response error:', {
        status: response.status,
        text: errorText
      });
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    console.log('Surveys data received:', data);
    
    return Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching surveys:', error);
    throw error;
  }
};

export const getSurvey = async (surveyId) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${await user.getIdToken()}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Retrieved survey data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching survey:', error);
    throw error;
  }
};

export const checkUserResponse = (surveyId) => get(`/api/checkResponse/${surveyId}`);
export const submitSurveyResponse = (surveyId, answers) => post('/api/submitResponse', { surveyId, answers });
export const getSurveyWithResponses = (surveyId) => get(`/api/surveys/${surveyId}/with-responses`);
export const deleteSurvey = async (surveyId) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    if (!token) {
      throw new Error('No authentication token available');
    }

    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Delete response error:', {
        status: response.status,
        text: errorText
      });
      throw new Error(`Failed to delete survey: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in deleteSurvey:', error);
    throw error;
  }
};

// Aliases for consistency with existing code
export const fetchSurvey = async (surveyId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}/public`);
    if (!response.ok) {
      throw new Error('Failed to fetch survey');
    }
    const data = await response.json();
    
    // Ensure styles object exists and backgroundImage is never null
    if (data.styles) {
      data.styles.backgroundImage = data.styles.backgroundImage || '';
    } else {
      data.styles = {
        ...data.styles,
        backgroundImage: ''
      };
    }

    console.log('Survey data from server:', data);
    console.log('Survey styles:', data.styles);
    return data;
  } catch (error) {
    console.error('Error in fetchSurvey:', error);
    throw error;
  }
};

export const checkResponse = async (surveyId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/checkResponse/${surveyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  } catch (error) {
    console.error('Error checking response:', error);
    return { hasResponded: false };
  }
};

export const submitResponse = async (surveyId, answers, completionTime) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/submitResponse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ surveyId, answers, completionTime })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error submitting response:', error);
    throw error;
  }
};

export const duplicateSurvey = async (surveyId) => {
  const token = await auth.currentUser?.getIdToken();
  
  try {
    const response = await fetch(`${API_BASE_URL}/api/duplicateSurvey/${surveyId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error duplicating survey:', error);
    throw error;
  }
};

export const updateSurveyLogo = async (surveyId, logoData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    // First get the current survey data
    const currentSurvey = await getSurvey(surveyId);
    
    // Merge the new logo data with existing styles
    const updatedStyles = {
      ...currentSurvey.styles,
      logo: {
        url: logoData.url,
        size: logoData.size,
        alignment: logoData.alignment
      }
    };

    const token = await user.getIdToken();
    
    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId: surveyId,
        title: currentSurvey.title,
        questions: currentSurvey.questions,
        styles: updatedStyles
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating survey logo:', error);
    throw error;
  }
};

export const uploadMediaFile = async (file) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const storage = getStorageInstance();
    
    // Create file reference with timestamp
    const timestamp = Date.now();
    const fileName = `logos/${user.uid}/${timestamp}_${file.name}`;
    const storageRef = ref(storage, fileName);

    // Upload file
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);

    return {
      fileId: fileName,
      name: file.name,
      url: url,
      type: 'image',
      createdAt: new Date(timestamp).toISOString(),
      isLogo: true
    };
  } catch (error) {
    console.error('Error uploading media:', error);
    throw error;
  }
};

export const getMediaFiles = async () => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    const storage = getStorageInstance();
    const mediaRef = ref(storage, `logos/${user.uid}`);
    
    try {
      const mediaList = await listAll(mediaRef);
      const validFiles = await Promise.all(
        mediaList.items.map(async (item) => {
          try {
            const url = await getDownloadURL(item);
            
            // Verify the file is accessible
            try {
              const response = await fetch(url, { method: 'HEAD' });
              if (!response.ok) {
                // File not accessible, try to delete it
                try {
                  await deleteObject(item);
                } catch (deleteError) {
                  console.log('Could not delete reference:', deleteError);
                }
                return null;
              }
              
              return {
                fileId: item.name,
                name: item.name.split('_').slice(1).join('_'),
                url: url,
                fullPath: item.fullPath,
                type: 'image',
                createdAt: new Date(parseInt(item.name.split('_')[0])).toISOString(),
                isUploadedFile: true
              };
            } catch (fetchError) {
              // File not accessible, try to delete it
              try {
                await deleteObject(item);
              } catch (deleteError) {
                console.log('Could not delete reference:', deleteError);
              }
              return null;
            }
          } catch (error) {
            // If we can't get URL, file probably doesn't exist
            try {
              await deleteObject(item);
            } catch (deleteError) {
              console.log('Could not delete reference:', deleteError);
            }
            return null;
          }
        })
      );

      // Filter out null values (deleted/inaccessible files)
      return validFiles.filter(file => file !== null);
      
    } catch (error) {
      console.error('Error listing files:', error);
      return [];
    }
  } catch (error) {
    console.error('Error fetching media:', error);
    throw error;
  }
};

export const deleteMediaFile = async (fileId) => {
  try {
    const token = await auth.currentUser?.getIdToken();
    const response = await fetch(`${API_BASE_URL}/api/media/${fileId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) throw new Error('Failed to delete media file');
    return await response.json();
  } catch (error) {
    console.error('Error deleting media:', error);
    throw error;
  }
};

export const getTemplates = async () => {
  try {
    const response = await get('/api/templates');
    return response;
  } catch (error) {
    console.error('Error fetching templates:', error);
    throw error;
  }
};

export const createSurveyFromTemplate = async (templateId) => {
  try {
    const response = await post('/api/templates/create-survey', { templateId });
    return response;
  } catch (error) {
    console.error('Error creating survey from template:', error);
    throw error;
  }
};

// Add this function near the top for testing
export const testCreateAndFetchSurvey = async () => {
  try {
    console.log('Creating test survey...');
    const newSurvey = await createSurvey();
    console.log('Created survey:', newSurvey);
    
    console.log('Fetching all surveys...');
    const surveys = await getSurveys();
    console.log('Fetched surveys:', surveys);
    
    return { newSurvey, surveys };
  } catch (error) {
    console.error('Test failed:', error);
    throw error;
  }
};

export const recordSurveyView = async (surveyId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/surveys/${surveyId}/view`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error recording survey view:', error);
    return { success: false };
  }
};

export const updateSurveyBackground = async (surveyId, backgroundData) => {
  try {
    const user = await getCurrentUser();
    if (!user) throw new Error('No authenticated user');

    // First get the current survey data
    const currentSurvey = await getSurvey(surveyId);
    
    // Merge the new background data with existing styles
    const updatedStyles = {
      ...currentSurvey.styles,
      backgroundImage: backgroundData.url
    };

    const token = await user.getIdToken();
    
    const response = await fetch(`${API_BASE_URL}/api/saveSurvey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        surveyId: surveyId,
        title: currentSurvey.title,
        questions: currentSurvey.questions,
        styles: updatedStyles
      })
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating survey background:', error);
    throw error;
  }
};
