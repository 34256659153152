import React from 'react';

const Shimmer = () => (
  <div className="absolute inset-0">
    <div className="relative w-full h-full overflow-hidden">
      <div 
        className="absolute inset-0 -translate-x-full animate-[shimmer_2s_infinite] 
        bg-gradient-to-r from-transparent via-white/60 to-transparent"
      />
    </div>
  </div>
);

const QuestionSkeleton = ({ isActive }) => (
  <div className={`
    relative rounded-lg p-4 
    ${isActive 
      ? 'bg-blue-50 border-2 border-blue-500' 
      : 'border border-gray-200'
    }
  `}>
    {/* Number badge */}
    <div className={`
      absolute -left-2 -top-2 w-6 h-6 rounded-full
      ${isActive ? 'bg-blue-500' : 'bg-gray-200'}
    `} />
    
    {/* Icon and title */}
    <div className="flex items-center gap-2 mb-3">
      <div className="w-4 h-4 rounded bg-gray-200" />
      <div className="h-4 w-24 rounded bg-gray-200" />
    </div>
    
    {/* Question preview */}
    <div className="space-y-2">
      <div className="h-2.5 w-full rounded bg-gray-200" />
      <div className="h-2.5 w-4/5 rounded bg-gray-200" />
    </div>
    <Shimmer />
  </div>
);

const SurveyDesignerSkeleton = () => {
  return (
    <div className="fixed inset-0 overflow-hidden">
      <div className="h-full">
        {/* Main Content - 3-column layout */}
        <div className="flex h-full bg-gray-50 pt-12">
          {/* Left Panel - Questions List (13.3%) */}
          <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
            <div className="space-y-2 p-4">
              {[...Array(4)].map((_, i) => (
                <QuestionSkeleton key={i} isActive={i === 0} />
              ))}
            </div>
          </div>

          {/* Middle Panel - Preview (73.4%) */}
          <div className="w-[73.4%] bg-gray-50 overflow-hidden px-4 pt-4">
            <div className="h-full">
              {/* Preview Controls */}
              <div className="sticky top-0 bg-gray-50 z-10">
                <div className="bg-white rounded-xl shadow-sm border border-gray-200">
                  <div className="flex items-center justify-between px-3 py-2">
                    {/* Add Question Button */}
                    <div className="w-28 h-8 rounded-md bg-gray-100" />
                    {/* Right Controls */}
                    <div className="flex items-center gap-2">
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <div className="w-8 h-8 rounded" />
                        <div className="w-8 h-8 rounded" />
                      </div>
                      <div className="w-20 h-8 rounded-md bg-gray-100" />
                      <div className="w-20 h-8 rounded-md bg-gray-100" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Preview Container */}
              <div className="h-full w-full relative flex items-start justify-center overflow-hidden pb-10">
                <div className="h-full overflow-hidden border border-gray-200 flex flex-col w-full max-w-[2000px] mx-6 max-h-[calc(100vh-220px)] bg-white shadow-sm mt-6">
                  {/* Progress Bar */}
                  <div className="w-full bg-gray-200 h-2">
                    <div className="bg-blue-500 h-2 w-1/4" />
                  </div>
                  
                  <div className="text-xs text-gray-500 text-center mt-3 mb-5">
                    Question 1 of 4
                  </div>

                  {/* Question Content */}
                  <div className="flex-1 flex flex-col items-center mt-4">
                    <div className="w-full max-w-3xl px-4">
                      <div className="flex flex-col items-center">
                        <div className="space-y-3 w-full">
                          <div className="h-8 bg-gray-100 rounded w-3/4 mx-auto" />
                          <div className="h-8 bg-gray-100 rounded w-1/2 mx-auto" />
                        </div>
                        <div className="space-y-4 w-full mt-8">
                          {[...Array(4)].map((_, i) => (
                            <div key={i} className="h-12 bg-gray-50 rounded-lg" />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Panel - Editor (13.3%) */}
          <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
            {/* Tabs */}
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px px-2 pt-2">
                {['Edit', 'Style'].map((tab) => (
                  <div
                    key={tab}
                    className="px-3 py-2 text-xs font-medium border-b-2 border-transparent"
                  >
                    <div className="w-8 h-4 bg-gray-200 rounded" />
                  </div>
                ))}
              </nav>
            </div>

            {/* Editor Content */}
            <div className="p-4 space-y-6">
              {/* Question Type */}
              <div className="space-y-2">
                <div className="w-24 h-4 bg-gray-200 rounded" />
                <div className="h-10 bg-gray-100 rounded-lg" />
              </div>

              {/* Brand Logo */}
              <div className="space-y-2 mt-8">
                <div className="w-20 h-4 bg-gray-200 rounded" />
                <div className="h-32 bg-gray-100 rounded-lg" />
              </div>

              {/* Logo Controls */}
              <div className="space-y-4">
                <div className="space-y-2">
                  <div className="w-16 h-4 bg-gray-200 rounded" />
                  <div className="h-10 bg-gray-100 rounded-lg" />
                </div>
                <div className="space-y-2">
                  <div className="w-24 h-4 bg-gray-200 rounded" />
                  <div className="h-10 bg-gray-100 rounded-lg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyDesignerSkeleton;
