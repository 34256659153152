import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Search, ChevronDown, BarChart2, Mail, MessageSquare, Database, Code, Zap, Layout } from 'lucide-react';

// Import integration logos
const LOGOS = {
  GOOGLE_SHEETS: "https://upload.wikimedia.org/wikipedia/commons/3/30/Google_Sheets_logo_%282014-2020%29.svg",
  GOOGLE_ANALYTICS: "https://upload.wikimedia.org/wikipedia/commons/8/89/Logo_Google_Analytics.svg",
  FACEBOOK_PIXEL: "https://upload.wikimedia.org/wikipedia/commons/0/05/Facebook_Logo_%282019%29.png",
  MAILCHIMP: "https://www.svgrepo.com/show/349445/mailchimp.svg",
  NOTION: "https://upload.wikimedia.org/wikipedia/commons/4/45/Notion_app_logo.png",
  SLACK: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Slack_icon_2019.svg",
  SALESFORCE: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg",
  GTM: "https://www.svgrepo.com/show/353827/google-tag-manager.svg"
};

const ALL_INTEGRATIONS = [
  {
    name: 'Google Analytics',
    logo: LOGOS.GOOGLE_ANALYTICS,
    description: 'Track survey performance with Google Analytics',
    category: 'Analytics',
    comingSoon: false
  },
  {
    name: 'Facebook Pixel',
    logo: LOGOS.FACEBOOK_PIXEL,
    description: 'Track conversions and retarget survey participants',
    category: 'Analytics',
    comingSoon: true
  },
  {
    name: 'Google Tag Manager',
    logo: LOGOS.GTM,
    description: 'Manage all your marketing tags',
    category: 'Analytics',
    comingSoon: true
  },
  {
    name: 'Mailchimp',
    logo: LOGOS.MAILCHIMP,
    description: 'Add respondents to your email lists',
    category: 'Communication',
    comingSoon: true
  },
  {
    name: 'Slack',
    logo: LOGOS.SLACK,
    description: 'Get survey notifications in Slack',
    category: 'Communication',
    comingSoon: true
  },
  {
    name: 'Google Sheets',
    logo: LOGOS.GOOGLE_SHEETS,
    description: 'Export responses directly to Google Sheets',
    category: 'Data Management',
    comingSoon: false
  },
  {
    name: 'Notion',
    logo: LOGOS.NOTION,
    description: 'Save responses to Notion databases',
    category: 'Data Management',
    comingSoon: true
  },
  {
    name: 'Salesforce',
    logo: LOGOS.SALESFORCE,
    description: 'Sync responses with Salesforce',
    category: 'CRM',
    comingSoon: true
  },
  {
    name: 'Webhooks',
    logo: null,
    description: 'Send responses to any endpoint',
    category: 'Developer Tools',
    comingSoon: true
  },
  {
    name: 'API Access',
    logo: null,
    description: 'Access survey data programmatically',
    category: 'Developer Tools',
    comingSoon: true
  }
];

const INTEGRATION_CATEGORIES = [
  {
    id: 'all',
    name: 'All Integrations',
    icon: Layout,
    integrations: ALL_INTEGRATIONS
  },
  {
    id: 'analytics',
    name: 'Analytics',
    icon: BarChart2,
    integrations: ALL_INTEGRATIONS.filter(i => i.category === 'Analytics')
  },
  {
    id: 'communication',
    name: 'Communication',
    icon: Mail,
    integrations: ALL_INTEGRATIONS.filter(i => i.category === 'Communication')
  },
  {
    id: 'data',
    name: 'Data Management',
    icon: Database,
    integrations: ALL_INTEGRATIONS.filter(i => i.category === 'Data Management')
  },
  {
    id: 'crm',
    name: 'CRM',
    icon: MessageSquare,
    integrations: ALL_INTEGRATIONS.filter(i => i.category === 'CRM')
  },
  {
    id: 'developer',
    name: 'Developer Tools',
    icon: Code,
    integrations: ALL_INTEGRATIONS.filter(i => i.category === 'Developer Tools')
  }
];

const SurveyIntegrate = () => {
  const { surveyId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(INTEGRATION_CATEGORIES[0]);

  const filteredIntegrations = selectedCategory.integrations.filter(integration =>
    integration.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-50 pt-14">
      <div className="flex h-[calc(100vh-56px)]">
        {/* Left Sidebar - 13.3% */}
        <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
          <div className="p-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search integrations..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <Search className="absolute left-3 top-2.5 text-gray-400" size={16} />
            </div>
          </div>

          <div className="px-2">
            {INTEGRATION_CATEGORIES.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category)}
                className={`w-full flex items-center gap-3 px-4 py-2.5 text-sm font-medium rounded-lg transition-colors ${
                  selectedCategory.id === category.id
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                {React.createElement(category.icon, {
                  size: 16,
                  className: selectedCategory.id === category.id ? 'text-blue-600' : 'text-gray-400'
                })}
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Main Content - 73.4% */}
        <div className="w-[73.4%] bg-gray-50 overflow-y-auto px-4 pt-4">
          <div className="max-w-5xl mx-auto">
            <div className="flex items-center justify-between mb-8">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{selectedCategory.name}</h1>
                <p className="mt-1 text-sm text-gray-500">
                  Connect your survey with {selectedCategory.name.toLowerCase()} tools
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4">
              {filteredIntegrations.map((integration) => (
                <div
                  key={integration.name}
                  className={`flex items-center justify-between p-6 bg-white rounded-xl border border-gray-200 transition-all ${
                    integration.comingSoon ? 'opacity-60' : 'hover:border-blue-500'
                  }`}
                >
                  {/* Integration card content remains the same */}
                  <div className="flex items-center gap-6">
                    <div className="w-12 h-12 flex-shrink-0 bg-white rounded-lg p-2 border border-gray-100">
                      {integration.logo ? (
                        <img
                          src={integration.logo}
                          alt={integration.name}
                          className="w-full h-full object-contain"
                        />
                      ) : (
                        <Code className="w-full h-full text-gray-400" />
                      )}
                    </div>
                    <div>
                      <div className="flex items-center gap-3 mb-1">
                        <h3 className="text-lg font-medium text-gray-900">
                          {integration.name}
                        </h3>
                      </div>
                      <p className="text-sm text-gray-500 max-w-xl">
                        {integration.description}
                      </p>
                    </div>
                  </div>

                  <div>
                    {integration.comingSoon ? (
                      <span className="px-3 py-1 text-xs font-medium text-gray-500 rounded-full bg-gray-100">
                        Coming soon
                      </span>
                    ) : (
                      <button className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors">
                        <Zap size={16} />
                        Connect
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Sidebar - 13.3% */}
        <div className="w-[13.3%] min-w-[260px] overflow-y-auto border border-gray-200 bg-white rounded-xl shadow-sm m-4">
          <div className="sticky top-0 bg-white z-20">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px px-2 pt-2">
                <button
                  className="px-3 py-2 text-xs font-medium border-b-2 border-blue-500 text-blue-600"
                >
                  Settings
                </button>
              </nav>
            </div>
          </div>

          <div className="p-4">
            <div className="space-y-4">
              {/* Integration Settings */}
              <div>
                <label className="block text-xs font-medium text-gray-600 mb-2">
                  Webhook URL
                </label>
                <input
                  type="text"
                  placeholder="https://"
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-xs font-medium text-gray-600 mb-2">
                  Authentication
                </label>
                <select
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="none">No Authentication</option>
                  <option value="basic">Basic Auth</option>
                  <option value="bearer">Bearer Token</option>
                </select>
              </div>

              <div>
                <label className="block text-xs font-medium text-gray-600 mb-2">
                  Event Triggers
                </label>
                <div className="space-y-2">
                  <label className="flex items-center">
                    <input type="checkbox" className="rounded border-gray-300 text-blue-600" />
                    <span className="ml-2 text-sm text-gray-600">On Form Submit</span>
                  </label>
                  <label className="flex items-center">
                    <input type="checkbox" className="rounded border-gray-300 text-blue-600" />
                    <span className="ml-2 text-sm text-gray-600">On Question Answer</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyIntegrate; 