import React from 'react';
import { X } from 'lucide-react';

const LogicConfigurator = ({ 
  question, 
  questions, 
  onUpdate, 
  onClose 
}) => {
  const handleEnableLogic = (enabled) => {
    const updatedQuestion = {
      ...question,
      skipLogic: {
        enabled,
        conditions: enabled ? [{ answer: '', skipToQuestionId: '' }] : []
      }
    };
    onUpdate(updatedQuestion);
  };

  const handleConditionUpdate = (index, field, value) => {
    const updatedConditions = [...question.skipLogic.conditions];
    updatedConditions[index] = {
      ...updatedConditions[index],
      [field]: value
    };

    const updatedQuestion = {
      ...question,
      skipLogic: {
        ...question.skipLogic,
        conditions: updatedConditions
      }
    };
    onUpdate(updatedQuestion);
  };

  const availableQuestions = questions.filter(q => q.id !== question.id);

  return (
    <div className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Question Logic</h3>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
          <X size={20} />
        </button>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="enableLogic"
            checked={question.skipLogic?.enabled || false}
            onChange={(e) => handleEnableLogic(e.target.checked)}
            className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
          />
          <label htmlFor="enableLogic" className="text-sm text-gray-700">
            Enable skip logic for this question
          </label>
        </div>

        {question.skipLogic?.enabled && (
          <div className="space-y-4">
            {question.skipLogic.conditions.map((condition, index) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-600">If answer is</span>
                  <select
                    value={condition.answer}
                    onChange={(e) => handleConditionUpdate(index, 'answer', e.target.value)}
                    className="flex-1 p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                  >
                    <option value="">Select answer</option>
                    {question.options?.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-600">Skip to</span>
                  <select
                    value={condition.skipToQuestionId}
                    onChange={(e) => handleConditionUpdate(index, 'skipToQuestionId', e.target.value)}
                    className="flex-1 p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500"
                  >
                    <option value="">Select question</option>
                    {availableQuestions.map((q) => (
                      <option key={q.id} value={q.id}>
                        {q.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LogicConfigurator;
