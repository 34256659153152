import React, { useState, useRef, useEffect } from 'react';
import { getAuthInstance, getStorageInstance } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { updateProfile, updateEmail, updatePassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { uploadMediaFile, getMediaFiles, deleteMediaFile } from '../serverComm';
import { Upload, CreditCard, Bell, User, Check, AlertCircle, Loader2, Image, Trash2, Plus, Eye } from 'lucide-react';

const CloseButton = ({ onClose }) => (
  <button
    onClick={onClose}
    className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
      <path d="M18 6L6 18M6 6l12 12" />
    </svg>
  </button>
);

const ProfileSettings = ({ isOpen, onClose }) => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();
  const [user] = useAuthState(auth);    
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [activeTab, setActiveTab] = useState('profile');
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    displayName: user?.displayName || '',
    email: user?.email || '',
    newPassword: '',
    confirmPassword: '',
    currentPassword: ''
  });

  const [mediaFiles, setMediaFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeTab === 'media') {
      fetchMediaFiles();
    }
  }, [activeTab]);

  const fetchMediaFiles = async () => {
    try {
      setIsLoading(true);
      const files = await getMediaFiles();
      setMediaFiles(files);
    } catch (error) {
      setMessage({ type: 'error', text: 'Error fetching media files' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const storageRef = ref(storage, `profile-images/${user.uid}`);
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);
      
      await updateProfile(user, {
        photoURL: photoURL
      });

      setMessage({ type: 'success', text: 'Profile picture updated successfully!' });
    } catch (error) {
      setMessage({ type: 'error', text: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleMediaUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsLoading(true);
      const uploadedFile = await uploadMediaFile(file);
      setMediaFiles(prevFiles => [uploadedFile, ...prevFiles]);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage({ 
        type: 'error', 
        text: 'Failed to upload file. Please try again.' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMedia = async (fileId) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;

    try {
      setIsLoading(true);
      await deleteMediaFile(fileId);
      setMediaFiles(prev => prev.filter(file => file.fileId !== fileId));
      setMessage({ type: 'success', text: 'File deleted successfully!' });
    } catch (error) {
      setMessage({ type: 'error', text: 'Error deleting file' });
    } finally {
      setIsLoading(false);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const getFileIcon = (type) => {
    if (type.startsWith('image/')) return <Image className="w-6 h-6" />;
    return <Eye className="w-6 h-6" />;
  };

  const tabs = [
    { id: 'profile', label: 'Profile', icon: User },
    { id: 'billing', label: 'Billing', icon: CreditCard },
    { id: 'notifications', label: 'Notifications', icon: Bell },
    { id: 'media', label: 'Media Library', icon: Image },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-8">
            {/* Profile Section */}
            <div className="flex items-start space-x-8">
              <div className="relative group">
                <img
                  src={user?.photoURL || 'https://via.placeholder.com/150'}
                  alt="Profile"
                  className="w-32 h-32 rounded-2xl object-cover ring-2 ring-gray-100"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="absolute bottom-2 right-2 p-2 bg-black text-white rounded-xl shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-200 hover:bg-gray-900"
                >
                  <Upload size={18} />
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  accept="image/*"
                  className="hidden"
                />
              </div>
              <div className="flex-1">
                <h3 className="text-2xl font-semibold text-gray-900">{user?.displayName}</h3>
                <p className="text-gray-500 mb-4">{user?.email}</p>
                <div className="flex space-x-3">
                  <span className="inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium bg-blue-50 text-blue-700">
                    Free Plan
                  </span>
                  <span className="inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium bg-gray-100 text-gray-700">
                    {user?.emailVerified ? 'Verified' : 'Unverified'}
                  </span>
                </div>
              </div>
            </div>

            {/* Form Fields */}
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Display Name
                  </label>
                  <input
                    type="text"
                    value={formData.displayName}
                    onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                    className="w-full px-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-black focus:border-black transition-colors"
                    placeholder="Enter your name"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-black focus:border-black transition-colors"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 'billing':
        return (
          <div className="space-y-8">
            {/* Current Plan */}
            <div className="bg-gray-50 p-6 rounded-xl border border-gray-200">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-semibold mb-2">Free Plan</h3>
                  <p className="text-gray-600">Basic features included</p>
                </div>
                <button className="bg-black text-white px-6 py-3 rounded-xl hover:bg-gray-900 transition-all duration-200 font-medium">
                  Upgrade Plan
                </button>
              </div>
            </div>

            {/* Payment Methods */}
            <div className="border border-gray-200 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-4">Payment Methods</h3>
              <button className="flex items-center space-x-2 text-black hover:text-gray-700 font-medium">
                <CreditCard size={20} />
                <span>Add Payment Method</span>
              </button>
            </div>

            {/* Billing History */}
            <div className="border border-gray-200 rounded-xl p-6">
              <h3 className="text-xl font-semibold mb-4">Billing History</h3>
              <div className="text-gray-500">
                No billing history available
              </div>
            </div>
          </div>
        );

      case 'notifications':
        return (
          <div className="space-y-6">
            <h3 className="text-xl font-semibold mb-6">Notification Preferences</h3>
            <div className="space-y-6">
              {[
                { label: 'Email notifications', description: 'Receive email updates about your account' },
                { label: 'Survey responses', description: 'Get notified when someone responds to your survey' },
                { label: 'Marketing updates', description: 'Receive news and promotional offers' }
              ].map((item) => (
                <div key={item.label} className="flex items-center justify-between p-4 border border-gray-200 rounded-xl">
                  <div>
                    <p className="font-medium text-gray-900">{item.label}</p>
                    <p className="text-sm text-gray-500">{item.description}</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        );

      case 'media':
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h3 className="text-xl font-semibold">Media Library</h3>
              <p className="text-sm text-gray-500 mt-1">Manage your uploaded images and survey logos</p>
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center py-12">
                <Loader2 className="animate-spin" size={24} />
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {/* Upload Card - Always First */}
                <label
                  htmlFor="media-upload"
                  className="aspect-square relative bg-gray-50 border-2 border-dashed border-gray-200 rounded-xl overflow-hidden hover:border-gray-300 transition-colors cursor-pointer group flex flex-col items-center justify-center p-4"
                >
                  <div className="p-2 rounded-full bg-gray-100 group-hover:bg-gray-200 transition-colors">
                    <Plus size={24} className="text-gray-500" />
                  </div>
                  <p className="text-sm text-gray-500 mt-2">Upload Logo</p>
                </label>

                {/* Media Files */}
                {mediaFiles.map((file) => (
                  <div
                    key={file.fileId}
                    className="group relative bg-white border border-gray-200 rounded-xl overflow-hidden"
                  >
                    <div className="aspect-square relative bg-white flex items-center justify-center p-4">
                      <img
                        src={file.url}
                        alt={file.name}
                        className="object-contain w-full h-full"
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%'
                        }}
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-200">
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100">
                          <div className="flex space-x-2">
                            <button
                              onClick={() => window.open(file.url, '_blank')}
                              className="p-2 bg-white text-black rounded-lg hover:bg-gray-100 transition-colors"
                              title="View"
                            >
                              <Eye size={20} />
                            </button>
                            {!file.isLogo && (
                              <button
                                onClick={() => handleDeleteMedia(file.fileId)}
                                className="p-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                title="Delete"
                              >
                                <Trash2 size={20} />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 bg-white border-t border-gray-200">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium truncate" title={file.name}>
                          {file.name}
                        </p>
                        {file.isLogo && (
                          <span className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                            Logo
                          </span>
                        )}
                      </div>
                      <p className="text-xs text-gray-500 mt-1">
                        {new Date(file.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            {/* Hidden file input */}
            <input
              type="file"
              id="media-upload"
              onChange={handleMediaUpload}
              className="hidden"
              accept="image/*"
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    isOpen && (
      <div 
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
        onClick={() => onClose()}
      >
        <div 
          className="relative bg-white rounded-xl shadow-xl w-[1100px] h-[700px] overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <CloseButton onClose={onClose} />

          {/* Header - Fixed height */}
          <div className="flex items-center h-[60px] px-6 border-b">
            <h2 className="text-2xl font-semibold">Settings</h2>
          </div>

          {/* Content Container - Remaining height */}
          <div className="h-[640px] grid grid-cols-12">
            {/* Sidebar - Full height */}
            <div className="col-span-3 border-r">
              <nav className="p-4 space-y-1">
                {tabs.map((tab) => {
                  const Icon = tab.icon;
                  return (
                    <button
                      key={tab.id}
                      onClick={() => setActiveTab(tab.id)}
                      className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg transition-colors ${
                        activeTab === tab.id
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      <Icon size={20} />
                      <span className="font-medium">{tab.label}</span>
                    </button>
                  );
                })}
              </nav>
            </div>

            {/* Main Content - Scrollable */}
            <div className="col-span-9 p-6 overflow-y-auto">
              {renderTabContent()}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProfileSettings;