import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSurvey, saveSurvey } from './serverComm';
import LoadingAnimation from './components/LoadingAnimation';
import EmployeeTemplateCardBg from './Employee_template_card_bg.svg';

// Fallback templates if database fetch fails
const LOCAL_TEMPLATES = [
  {
    id: 'customer-feedback',
    title: 'Customer Feedback Survey',
    description: 'Gather insights about customer satisfaction and product experience',
    category: 'Business',
    questions: [
      {
        id: '1',
        type: 'star rating',
        text: 'How satisfied are you with our product/service?',
        required: true
      },
      {
        id: '2',
        type: 'multiple choice',
        text: 'How likely are you to recommend us to others?',
        options: ['Very likely', 'Likely', 'Neutral', 'Unlikely', 'Very unlikely'],
        required: true
      },
      {
        id: '3',
        type: 'multiple choice',
        text: 'How would you rate our customer service?',
        options: ['Excellent', 'Good', 'Average', 'Poor', 'Very poor'],
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'How did you first hear about us?',
        options: ['Social Media', 'Search Engine', 'Friend/Family', 'Advertisement', 'Other'],
        required: false
      },
      {
        id: '5',
        type: 'multiple choice',
        text: 'How long have you been using our product/service?',
        options: ['Less than a month', '1-6 months', '6-12 months', '1-2 years', 'More than 2 years'],
        required: true
      },
      {
        id: '6',
        type: 'multiple choice',
        text: 'What was the main reason you chose our product/service?',
        options: ['Price', 'Features', 'Ease of use', 'Customer service', 'Brand reputation', 'Other'],
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'How often do you use our product/service?',
        options: ['Daily', 'Weekly', 'Monthly', 'Rarely', 'This is my first time'],
        required: false
      },
      {
        id: '8',
        type: 'long text',
        text: 'What features would you like to see added or improved?',
        required: false
      },
      {
        id: '9',
        type: 'long text',
        text: 'What improvements would you suggest for our product/service?',
        required: false
      },
      {
        id: '10',
        type: 'long text',
        text: 'Any additional comments or feedback?',
        required: false
      }
    ]
  },
  {
    id: 'employee-satisfaction',
    title: 'Employee Satisfaction Survey',
    description: 'Measure employee engagement and gather feedback about workplace satisfaction',
    category: 'HR',
    questions: [
      {
        id: '1',
        type: 'star rating',
        text: 'How satisfied are you with your role at our company?',
        required: true
      },
      {
        id: '2',
        type: 'multiple choice',
        text: 'How likely are you to recommend our company as a place to work?',
        options: ['Very likely', 'Likely', 'Neutral', 'Unlikely', 'Very unlikely'],
        required: true
      },
      {
        id: '3',
        type: 'multiple choice',
        text: 'How would you rate your work-life balance?',
        options: ['Excellent', 'Good', 'Average', 'Poor', 'Very poor'],
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'How satisfied are you with your current compensation and benefits?',
        options: ['Very satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very dissatisfied'],
        required: true
      },
      {
        id: '5',
        type: 'multiple choice',
        text: 'How would you rate the quality of communication from leadership?',
        options: ['Excellent', 'Good', 'Average', 'Poor', 'Very poor'],
        required: true
      },
      {
        id: '6',
        type: 'multiple choice',
        text: 'Do you feel you have opportunities for career growth within the company?',
        options: ['Definitely yes', 'Somewhat yes', 'Neutral', 'Somewhat no', 'Definitely no'],
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'How well do you feel your achievements are recognized?',
        options: ['Very well', 'Well', 'Adequately', 'Poorly', 'Very poorly'],
        required: true
      },
      {
        id: '8',
        type: 'multiple choice',
        text: 'How comfortable do you feel giving feedback to your manager?',
        options: ['Very comfortable', 'Comfortable', 'Neutral', 'Uncomfortable', 'Very uncomfortable'],
        required: true
      },
      {
        id: '9',
        type: 'multiple choice',
        text: 'How would you rate the company culture?',
        options: ['Excellent', 'Good', 'Average', 'Poor', 'Very poor'],
        required: true
      },
      {
        id: '10',
        type: 'multiple choice',
        text: 'Do you have the resources and tools needed to do your job effectively?',
        options: ['Definitely yes', 'Somewhat yes', 'Neutral', 'Somewhat no', 'Definitely no'],
        required: true
      },
      {
        id: '11',
        type: 'long text',
        text: 'What aspects of your job do you find most satisfying?',
        required: false
      },
      {
        id: '12',
        type: 'long text',
        text: 'What aspects of your job do you find most challenging?',
        required: false
      },
      {
        id: '13',
        type: 'long text',
        text: 'What suggestions do you have for improving the work environment?',
        required: false
      },
      {
        id: '14',
        type: 'long text',
        text: 'Any additional comments or feedback about your experience working here?',
        required: false
      }
    ]
  },
  {
    id: 'lead-generation',
    title: 'Lead Generation Form',
    description: 'Capture and qualify potential customer information for your sales team',
    category: 'Sales',
    questions: [
      {
        id: '1',
        type: 'short text',
        text: 'What is your full name?',
        required: true
      },
      {
        id: '2',
        type: 'short text',
        text: 'What is your work email address?',
        required: true
      },
      {
        id: '3',
        type: 'short text',
        text: 'What company do you work for?',
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'What is your role in the company?',
        options: [
          'C-Level Executive',
          'Director/Manager',
          'Individual Contributor',
          'Consultant',
          'Other'
        ],
        required: true
      },
      {
        id: '5',
        type: 'multiple choice',
        text: 'How many employees does your company have?',
        options: [
          '1-10',
          '11-50',
          '51-200',
          '201-500',
          '501-1000',
          '1000+'
        ],
        required: true
      },
      {
        id: '6',
        type: 'multiple choice',
        text: 'What is your primary interest in our product/service?',
        options: [
          'Evaluating solutions',
          'Comparing vendors',
          'Ready to purchase',
          'Just browsing',
          'Other'
        ],
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'What is your timeline for implementation?',
        options: [
          'Immediately',
          'Within 3 months',
          'Within 6 months',
          'Within a year',
          'No specific timeline'
        ],
        required: true
      },
      {
        id: '8',
        type: 'multiple choice',
        text: 'What is your estimated budget range?',
        options: [
          'Under $5,000',
          '$5,000 - $10,000',
          '$10,000 - $25,000',
          '$25,000+',
          'Not determined yet'
        ],
        required: false
      },
      {
        id: '9',
        type: 'multiple choice',
        text: 'How would you prefer to be contacted?',
        options: [
          'Email',
          'Phone',
          'Video call',
          'No preference'
        ],
        required: true
      },
      {
        id: '10',
        type: 'long text',
        text: 'What specific challenges are you looking to solve with our solution?',
        required: false
      },
      {
        id: '11',
        type: 'multiple choice',
        text: 'How did you hear about us?',
        options: [
          'Search Engine',
          'Social Media',
          'Referral',
          'Advertisement',
          'Industry Event',
          'Other'
        ],
        required: false
      },
      {
        id: '12',
        type: 'long text',
        text: 'Any additional information you\'d like to share?',
        required: false
      }
    ]
  },
  {
    id: 'product-research',
    title: 'Product Research Survey',
    description: 'Gather insights about product preferences, usage patterns, and market opportunities',
    category: 'Research',
    questions: [
      {
        id: '1',
        type: 'multiple choice',
        text: 'How often do you use products in this category?',
        options: [
          'Daily',
          'Several times a week',
          'Weekly',
          'Monthly',
          'Rarely',
          'Never'
        ],
        required: true
      },
      {
        id: '2',
        type: 'multiple choice',
        text: 'What factors influence your purchase decisions the most?',
        options: [
          'Price',
          'Quality',
          'Brand reputation',
          'Features',
          'Design',
          'Ease of use',
          'Customer reviews'
        ],
        required: true
      },
      {
        id: '3',
        type: 'multiple choice',
        text: 'What is your typical budget range for this type of product?',
        options: [
          'Under $50',
          '$50 - $100',
          '$101 - $250',
          '$251 - $500',
          'Over $500'
        ],
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'Where do you typically purchase these products?',
        options: [
          'Online marketplaces (Amazon, eBay)',
          'Direct from manufacturer',
          'Retail stores',
          'Specialty shops',
          'Other'
        ],
        required: true
      },
      {
        id: '5',
        type: 'multiple choice',
        text: 'How do you usually learn about new products?',
        options: [
          'Social media',
          'Online advertisements',
          'Friends/family recommendations',
          'Product review websites',
          'Traditional media (TV, radio, print)',
          'In-store displays'
        ],
        required: true
      },
      {
        id: '6',
        type: 'star rating',
        text: 'How satisfied are you with currently available products in this category?',
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'What features are most important to you?',
        options: [
          'Durability',
          'Performance',
          'Aesthetics',
          'Portability',
          'Customization options',
          'Environmental impact'
        ],
        required: true
      },
      {
        id: '8',
        type: 'multiple choice',
        text: 'How long do you typically use a product before replacing it?',
        options: [
          'Less than 6 months',
          '6-12 months',
          '1-2 years',
          '2-5 years',
          'More than 5 years'
        ],
        required: true
      },
      {
        id: '9',
        type: 'long text',
        text: 'What features or improvements would you like to see in future products?',
        required: false
      },
      {
        id: '10',
        type: 'multiple choice',
        text: 'How important is brand loyalty to you?',
        options: [
          'Very important - I stick to brands I trust',
          'Somewhat important - I prefer familiar brands but will try others',
          'Neutral',
          'Not very important - I frequently try new brands',
          'Not at all important - I buy based on other factors'
        ],
        required: true
      },
      {
        id: '11',
        type: 'multiple choice',
        text: 'What problems do you face with existing products?',
        options: [
          'Too expensive',
          'Poor quality',
          'Lack of features',
          'Difficult to use',
          'Poor customer support',
          'Limited availability',
          'None'
        ],
        required: true
      },
      {
        id: '12',
        type: 'long text',
        text: 'What would make you switch from your current preferred product to a new alternative?',
        required: false
      },
      {
        id: '13',
        type: 'multiple choice',
        text: 'How do you prefer to receive product support?',
        options: [
          'Online chat',
          'Email support',
          'Phone support',
          'Video tutorials',
          'Written documentation',
          'Community forums'
        ],
        required: true
      },
      {
        id: '14',
        type: 'long text',
        text: 'Any additional comments about your product preferences or needs?',
        required: false
      }
    ]
  },
  {
    id: 'market-research',
    title: 'Market Research Survey',
    description: 'Collect data about market trends, competitor analysis, and target audience preferences',
    category: 'Research',
    questions: [
      {
        id: '1',
        type: 'multiple choice',
        text: 'Which age group do you belong to?',
        options: ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'],
        required: true
      },
      {
        id: '2',
        type: 'multiple choice',
        text: 'What is your annual household income?',
        options: [
          'Under $25,000',
          '$25,000 - $49,999',
          '$50,000 - $74,999',
          '$75,000 - $99,999',
          '$100,000 - $149,999',
          '$150,000+'
        ],
        required: true
      },
      {
        id: '3',
        type: 'multiple choice',
        text: 'How often do you purchase products/services in this market?',
        options: ['Weekly', 'Monthly', 'Quarterly', 'Annually', 'Rarely', 'Never'],
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'Which brands do you currently use in this market? (Select all that apply)',
        options: ['Brand A', 'Brand B', 'Brand C', 'Brand D', 'Other'],
        required: true
      },
      {
        id: '5',
        type: 'star rating',
        text: 'How satisfied are you with your current brand?',
        required: true
      },
      {
        id: '6',
        type: 'multiple choice',
        text: 'What features are most important when choosing a product in this market?',
        options: ['Price', 'Quality', 'Brand reputation', 'Customer service', 'Features', 'Availability'],
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'Where do you typically research products before purchasing?',
        options: [
          'Social media',
          'Company websites',
          'Review websites',
          'Friends/family recommendations',
          'Professional reviews',
          'Other'
        ],
        required: true
      },
      {
        id: '8',
        type: 'multiple choice',
        text: 'What is your preferred shopping channel?',
        options: [
          'Physical stores',
          'Online marketplaces',
          'Brand websites',
          'Mobile apps',
          'Social media shops'
        ],
        required: true
      },
      {
        id: '9',
        type: 'long text',
        text: 'What improvements would you like to see in current market offerings?',
        required: false
      },
      {
        id: '10',
        type: 'multiple choice',
        text: 'How much would you be willing to pay for an improved product?',
        options: [
          '0-10% more than current prices',
          '11-25% more',
          '26-50% more',
          '51-100% more',
          'More than double current prices'
        ],
        required: true
      },
      {
        id: '11',
        type: 'multiple choice',
        text: 'What promotional offers most influence your purchase decisions?',
        options: [
          'Discounts',
          'Buy one get one free',
          'Free shipping',
          'Loyalty rewards',
          'Bundle deals',
          'None'
        ],
        required: true
      },
      {
        id: '12',
        type: 'long text',
        text: 'Any additional comments about the market or products?',
        required: false
      }
    ]
  },
  {
    id: 'branding-questionnaire',
    title: 'Branding Questionnaire',
    description: 'Understand brand perception and values to develop effective strategies',
    category: 'Marketing',
    questions: [
      {
        id: '1',
        type: 'multiple choice',
        text: 'How would you describe our brand in one word?',
        options: ['Innovative', 'Traditional', 'Luxurious', 'Friendly', 'Professional', 'Trendy', 'Other'],
        required: true
      },
      {
        id: '2',
        type: 'multiple choice',
        text: 'What emotions do you associate with our brand?',
        options: ['Trust', 'Excitement', 'Comfort', 'Confidence', 'Happiness', 'Sophistication', 'Other'],
        required: true
      },
      {
        id: '3',
        type: 'star rating',
        text: 'How well does our brand identity align with our products/services?',
        required: true
      },
      {
        id: '4',
        type: 'multiple choice',
        text: 'Which aspect of our brand stands out the most?',
        options: ['Logo', 'Color scheme', 'Typography', 'Messaging', 'Visual style', 'Brand voice'],
        required: true
      },
      {
        id: '5',
        type: 'multiple choice',
        text: 'How does our brand compare to competitors?',
        options: [
          'Much better',
          'Somewhat better',
          'About the same',
          'Somewhat worse',
          'Much worse'
        ],
        required: true
      },
      {
        id: '6',
        type: 'multiple choice',
        text: 'Which values do you associate with our brand?',
        options: [
          'Innovation',
          'Reliability',
          'Sustainability',
          'Quality',
          'Customer focus',
          'Social responsibility'
        ],
        required: true
      },
      {
        id: '7',
        type: 'multiple choice',
        text: 'How consistent is our brand across different platforms?',
        options: [
          'Very consistent',
          'Somewhat consistent',
          'Neutral',
          'Somewhat inconsistent',
          'Very inconsistent'
        ],
        required: true
      },
      {
        id: '8',
        type: 'multiple choice',
        text: 'What age group does our brand appear to target?',
        options: [
          'Gen Z (18-24)',
          'Young professionals (25-34)',
          'Mid-career (35-44)',
          'Established professionals (45-54)',
          'Senior (55+)',
          'All age groups'
        ],
        required: true
      },
      {
        id: '9',
        type: 'multiple choice',
        text: 'How would you rate our brand\'s visual appeal?',
        options: [
          'Very appealing',
          'Somewhat appealing',
          'Neutral',
          'Somewhat unappealing',
          'Very unappealing'
        ],
        required: true
      },
      {
        id: '10',
        type: 'long text',
        text: 'What suggestions do you have for improving our brand identity?',
        required: false
      },
      {
        id: '11',
        type: 'multiple choice',
        text: 'How memorable is our brand?',
        options: [
          'Very memorable',
          'Somewhat memorable',
          'Neutral',
          'Somewhat forgettable',
          'Very forgettable'
        ],
        required: true
      },
      {
        id: '12',
        type: 'multiple choice',
        text: 'Where do you most often encounter our brand?',
        options: [
          'Social media',
          'Website',
          'Physical stores',
          'Advertisements',
          'Word of mouth',
          'Other'
        ],
        required: true
      },
      {
        id: '13',
        type: 'long text',
        text: 'What do you think sets our brand apart from competitors?',
        required: false
      },
      {
        id: '14',
        type: 'long text',
        text: 'Any additional comments about our brand identity?',
        required: false
      }
    ]
  }
];

const Templates = ({ isOpen, onClose }) => {
  const [templates, setTemplates] = useState(LOCAL_TEMPLATES);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleUseTemplate = async (template) => {
    try {
      setIsLoading(true);
      const response = await createSurvey();
      const surveyId = response.surveyId;
      
      // Set default styles based on template
      const defaultStyles = {
        fontFamily: (() => {
          switch(template.id) {
            case 'market-research':
              return 'Roboto';  // Professional, clean font for market research
            case 'branding-questionnaire':
              return 'Montserrat';  // Modern, professional font for branding
            default:
              return 'Inter';
          }
        })(),
        submitButtonColor: (() => {
          switch(template.id) {
            case 'lead-generation':
              return '#0F8B6B';  // Deep green for lead generation
            case 'product-research':
              return '#2563EB';  // Blue for product research
            case 'market-research':
              return '#DC2626';  // Vibrant red for market research
            case 'branding-questionnaire':
              return '#7C3AED';  // Rich purple for branding
            default:
              return '#4F46E5';  // Default indigo
          }
        })(),
        backgroundColor: (() => {
          switch(template.id) {
            case 'employee-satisfaction':
              return '#DBC8FB';  // Purple for employee satisfaction
            case 'lead-generation':
              return '#D3FBF1';  // Mint green for lead generation
            case 'product-research':
              return '#EFF6FF';  // Light blue for product research
            case 'market-research':
              return '#FEF2F2';  // Light red background for market research
            case 'branding-questionnaire':
              return '#F5F3FF';  // Light purple background
            default:
              return '#FFFFFF';  // White for other templates
          }
        })(),
        useCustomSubmitText: false,
        customSubmitText: ''
      };
      
      await saveSurvey({
        surveyId: surveyId,
        title: template.title,
        questions: template.questions,
        styles: defaultStyles
      });
      
      onClose(); // Close the modal after creating the survey
      navigate(`/survey/${surveyId}`, { replace: true });
    } catch (error) {
      console.error('Error creating survey from template:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <LoadingAnimation />;

  return (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
      onClick={() => onClose()}
    >
      <div 
        className="bg-white rounded-3xl w-full max-w-[1200px] max-h-[90vh] overflow-y-auto relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button 
          onClick={onClose}
          className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>

        <div className="px-6 sm:px-8 py-8">
          <h1 className="text-2xl sm:text-3xl font-['HK_Nova'] font-medium tracking-[-1.08px] mb-8">
            Templates
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {templates.map((template) => (
              <div 
                key={template.id}
                className="bg-white border border-[#e4e4e4] rounded-[15px] overflow-hidden hover:shadow-lg transition-all duration-200"
              >
                <div 
                  className="h-[160px] flex items-center justify-center"
                  style={{ 
                    backgroundColor: 'transparent'
                  }}
                >
                  <img 
                    src={EmployeeTemplateCardBg} 
                    alt={`${template.title} Template`} 
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="p-6">
                  <h3 className="text-xl font-['HK_Nova'] font-medium mb-2">
                    {template.title}
                  </h3>
                  <p className="text-gray-600 mb-6 text-sm">
                    {template.description}
                  </p>
                  <button
                    onClick={() => handleUseTemplate(template)}
                    className="w-full h-11 rounded-xl font-medium transition-colors"
                    style={{ 
                      backgroundColor: '#4F46E5',
                      color: '#FFFFFF'
                    }}
                  >
                    Use Template
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;