import { getAuthInstance, getStorageInstance } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL, listAll, deleteObject } from 'firebase/storage';

// Add new function to fetch stock photos
export const getStockPhotos = async () => {
  return [
    {
      url: 'https://images.unsplash.com/photo-1508615039623-a25605d2b022?q=80&w=2000',
      id: '1',
    },
    {
      url: 'https://images.unsplash.com/photo-1557683316-973673baf926?q=80&w=2000',
      id: '2',
    },
    {
      url: 'https://images.unsplash.com/photo-1553356084-58ef4a67b2a7?q=80&w=2000',
      id: '3',
    },
    {
      url: 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?q=80&w=2000',
      id: '4',
    },
    {
      url: 'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2000',
      id: '5',
    },
    {
      url: 'https://images.unsplash.com/photo-1557672172-298e090bd0f1?q=80&w=2000',
      id: '6',
    },
    {
      url: 'https://images.unsplash.com/photo-1518655048521-f130df041f66?q=80&w=2000',
      id: '7',
    },
    {
      url: 'https://images.unsplash.com/photo-1614850523296-d8c1af93d400?q=80&w=2000',
      id: '8',
    },
    {
      url: 'https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?q=80&w=2000',
      id: '9',
    },
    {
      url: 'https://images.unsplash.com/photo-1558591710-4b4a1ae0f04d?q=80&w=2000',
      id: '10',
    },
    {
      url: 'https://images.unsplash.com/photo-1554147090-e1221a04a025?q=80&w=2000',
      id: '11',
    },
    {
      url: 'https://images.unsplash.com/photo-1557264337-e8a93017fe92?q=80&w=2000',
      id: '12',
    },
    {
      url: 'https://images.unsplash.com/photo-1618556450994-a6a128ef0d9d?q=80&w=2000',
      id: '13',
    },
    {
      url: 'https://images.unsplash.com/photo-1553356084-58ef4a67b2a7?q=80&w=2000',
      id: '14',
    },
    {
      url: 'https://images.unsplash.com/photo-1550859492-d5da9d8e45f3?q=80&w=2000',
      id: '15',
    },
    {
      url: 'https://images.unsplash.com/photo-1618172193763-c511deb635ca?q=80&w=2000',
      id: '16',
    },
    {
      url: 'https://images.unsplash.com/photo-1617791160505-6f00504e3519?q=80&w=2000',
      id: '17',
    },
    {
      url: 'https://images.unsplash.com/photo-1579547621869-0ddb5f237392?q=80&w=2000',
      id: '18',
    }
  ];
};

export const uploadLogo = async (file, surveyId) => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();

  if (!auth.currentUser) {
    throw new Error('User must be authenticated to upload files');
  }

  try {
    // Create file reference with timestamp
    const timestamp = Date.now();
    const fileName = `logos/${auth.currentUser.uid}/${timestamp}_${file.name}`;
    const storageRef = ref(storage, fileName);

    // Upload file
    await uploadBytesResumable(storageRef, file);
    const url = await getDownloadURL(storageRef);

    // Return a consistent format
    return {
      url,
      name: file.name,
      fullPath: fileName,
      timestamp: timestamp,
      type: 'image'
    };
  } catch (error) {
    console.error('Error in uploadLogo:', error);
    throw error;
  }
};

export const uploadBackground = async (file, surveyId) => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();

  try {
    if (!auth.currentUser) {
      throw new Error('User must be authenticated to upload files');
    }

    const timestamp = Date.now();
    const fileName = `backgrounds/${auth.currentUser.uid}/${timestamp}_${file.name}`;
    const storageRef = ref(storage, fileName);

    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Upload error:', error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error('GetDownloadURL error:', error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error('Error in uploadBackground:', error);
    throw error;
  }
};

export const getPreviousBackgrounds = async () => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();
  
  if (!auth.currentUser) {
    throw new Error('User must be authenticated to fetch backgrounds');
  }

  const backgroundsRef = ref(storage, `backgrounds/${auth.currentUser.uid}`);
  
  try {
    const result = await listAll(backgroundsRef);
    const urls = await Promise.all(
      result.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return {
          url,
          name: itemRef.name,
          fullPath: itemRef.fullPath,
          timestamp: parseInt(itemRef.name.split('.')[0].split('/').pop())
        };
      })
    );
    
    return urls.sort((a, b) => b.timestamp - a.timestamp);
  } catch (error) {
    console.error('Error fetching backgrounds:', error);
    return [];
  }
};

export const getPreviousLogos = async () => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();
  
  if (!auth.currentUser) {
    throw new Error('User must be authenticated to fetch logos');
  }

  // Reference to user's logos directory
  const logosRef = ref(storage, `logos/${auth.currentUser.uid}`);
  
  try {
    const result = await listAll(logosRef);
    const urls = await Promise.all(
      result.items.map(async (itemRef) => {
        try {
          // Try to get the download URL and verify the file exists
          const url = await getDownloadURL(itemRef);
          
          // Try to verify if the file is accessible
          const response = await fetch(url, { method: 'HEAD' });
          if (!response.ok) {
            // If file is not accessible, try to clean up the reference
            try {
              await deleteObject(itemRef);
            } catch (deleteError) {
              console.log('Could not delete reference:', deleteError);
            }
            return null;
          }

          return {
            url,
            name: itemRef.name,
            fullPath: itemRef.fullPath,
            timestamp: parseInt(itemRef.name.split('.')[0].split('/').pop())
          };
        } catch (error) {
          // If we can't get the URL, the file probably doesn't exist
          console.log('Skipping inaccessible file:', itemRef.fullPath);
          // Try to clean up the reference
          try {
            await deleteObject(itemRef);
          } catch (deleteError) {
            console.log('Could not delete reference:', deleteError);
          }
          return null;
        }
      })
    );
    
    // Filter out any null results (inaccessible/deleted files)
    const accessibleUrls = urls.filter(url => url !== null);
    return accessibleUrls.sort((a, b) => b.timestamp - a.timestamp);
  } catch (error) {
    console.error('Error fetching logos:', error);
    return [];
  }
};

// Update the deleteUploadedImage function
export const deleteUploadedImage = async (imagePath) => {
  const auth = getAuthInstance();
  const storage = getStorageInstance();

  if (!auth.currentUser) {
    throw new Error('User must be authenticated to delete files');
  }

  try {
    // Split the path into segments
    const pathSegments = imagePath.split('/');
    const fileName = pathSegments.pop();
    const folderPath = pathSegments.join('/');
    
    // Create a reference to the folder first, then to the file
    const folderRef = ref(storage, folderPath);
    const fileRef = ref(folderRef, fileName);
    
    // Delete the file
    await deleteObject(fileRef);
    return true;
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

// Update the deleteMediaFile function
export const deleteMediaFile = async (fileUrl) => {
  try {
    const auth = getAuthInstance();
    const storage = getStorageInstance();

    if (!auth.currentUser) {
      throw new Error('User must be authenticated to delete files');
    }

    try {
      // Extract the file path from the URL
      const decodedUrl = decodeURIComponent(fileUrl);
      const matches = decodedUrl.match(/logos\/[^?]+/);
      
      if (!matches) {
        console.warn('Invalid file path format, but continuing with UI update');
      } else {
        const filePath = matches[0];
        console.log('Attempting to delete:', filePath);

        // Create a reference to the file
        const fileRef = ref(storage, `${filePath}`);
        
        // Try to delete the file, but don't throw if it fails
        try {
          await deleteObject(fileRef);
        } catch (deleteError) {
          console.warn('File may already be deleted:', deleteError);
        }
      }
    } catch (storageError) {
      console.warn('Storage operation failed, but continuing with UI update:', storageError);
    }

    // Always refresh the media library, even if deletion failed
    const updatedLogos = await getPreviousLogos();
    return { success: true, updatedLogos };

  } catch (error) {
    console.error('Error in deleteMediaFile:', error);
    // Still try to get updated logos even if there was an error
    try {
      const updatedLogos = await getPreviousLogos();
      return { success: false, updatedLogos };
    } catch (logoError) {
      console.error('Failed to get updated logos:', logoError);
      throw error;
    }
  }
};