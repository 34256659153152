import React, { useState } from 'react';
import PageWrapper from './components/PageWrapper';
import HeroDash from './lp-hero-dash.svg';
import { 
  Zap, 
  TrendingUp, 
  Target,
  Users,
  LineChart,
  Briefcase,
  Calendar,
  GraduationCap,
  Box,
  TestTube,
  Heart,
  Building
} from 'lucide-react';
import LandingContent from './components/LandingContent';
import MainNavigation from './components/MainNavigation';
import Footer from './components/Footer';

const LandingPage = ({ openSignIn }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="min-h-screen w-full">
      {/* Yellow Hero Section */}
      <div className="bg-[#FFFD63]">
        <div className="max-w-[1920px] mx-auto px-4">
          <MainNavigation 
            openSignIn={openSignIn}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          <div className="max-w-[1600px] mx-auto text-center">
            {/* Hero Content */}
            <div className="pb-40">
              {/* Badge */}
              <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-4 py-2 mb-8">
                <div className="w-1.5 h-1.5 rounded-full bg-black mr-2"></div>
                <span className="text-sm font-medium text-gray-700">Simple & Powerful Surveys</span>
              </div>

              {/* Main Title */}
              <h1 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl md:text-[72px] leading-tight tracking-[-2px] mb-8">
                Lightning Fast Poll's,<br />
                <span className="relative inline-block">
                  Insightful
                  <div className="absolute -bottom-4 left-0 w-full h-3 bg-[#FFFD20] -z-10 transform -rotate-1"></div>
                </span> Feedback
              </h1>

              {/* Subtitle */}
              <p className="font-['HK_Nova'] text-xl sm:text-2xl text-gray-600 max-w-2xl mx-auto leading-relaxed mb-16">
                Discover the powerful survey platform anyone can use.<br className="hidden sm:block" />
                Made for those who need answers fast.
              </p>

              {/* CTA Buttons */}
              <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-20">
                <button 
                  className="px-10 py-5 text-lg rounded-2xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto group relative overflow-hidden shadow-lg hover:shadow-xl"
                  onClick={() => openSignIn(true)}
                >
                  <span className="relative z-10">Get Started Free</span>
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></div>
                </button>
                <button 
                  className="px-10 py-5 text-lg rounded-2xl bg-white hover:bg-white/80 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto shadow-md hover:shadow-lg group"
                  onClick={() => window.location.href = '/features'}
                >
                  See all features
                  <span className="inline-block ml-2 transform group-hover:translate-x-1 transition-transform">→</span>
                </button>
              </div>

              {/* Stats */}
              <div className="flex flex-col sm:flex-row justify-center gap-16 sm:gap-24 mb-24">
                {[
                  { number: "10K+", label: "Active Users" },
                  { number: "1M+", label: "Survey Responses" },
                  { number: "99%", label: "Satisfaction Rate" }
                ].map((stat, index) => (
                  <div key={index} className="text-center group">
                    <div className="text-5xl font-bold mb-3 font-['HK_Nova'] group-hover:scale-110 transition-transform">{stat.number}</div>
                    <div className="text-gray-600 font-medium">{stat.label}</div>
                  </div>
                ))}
              </div>

              {/* Hero Image */}
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-b from-[#FFFD63]/0 via-[#FFFD63]/5 to-[#FFFD63]/10 rounded-[32px] transform -rotate-1"></div>
                <img 
                  src={HeroDash} 
                  alt="Dashboard Preview" 
                  className="max-w-full h-auto mx-auto rounded-[32px]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* White Content Section */}
      <div className="bg-white">
        <div className="max-w-[1600px] mx-auto px-4 pt-20">
          <LandingContent openSignIn={openSignIn} />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
