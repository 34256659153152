import React, { useState } from 'react';
import PageWrapper from '../components/PageWrapper';
import PricingCard from '../components/PricingCard';

const PlansPage = ({ openSignIn }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);

  const prices = {
    pro: {
      monthly: 18,
      annual: 15
    },
    business: {
      monthly: 30,
      annual: 25
    }
  };

  const pricingData = {
    free: {
      plan: "Free",
      description: "Up to 3 Surveys",
      badge: "Enjoy free",
      features: [
        "Up to 3 Poll's per month",
        "100 responses per Poll's",
        "Basic analytics",
        "Email support"
      ],
      bgColor: "bg-[#FFFEF2]",
      buttonText: "Get Started",
      isFreePlan: true
    },
    pro: {
      plan: "Pro",
      description: "Unlimited Surveys",
      badge: "20% OFF",
      features: [
        "Unlimited Poll's",
        "1000 responses per poll",
        "Advanced analytics",
        "Custom branding",
        "All question types",
        "Advanced export options",
        "Priority email support"
      ],
      bgColor: "bg-[#FFFD63]"
    },
    growth: {
      plan: "Growth",
      description: "Ultimate Features",
      badge: "20% OFF",
      features: [
        "Unlimited Poll's",
        "1000 responses per poll",
        "Advanced analytics",
        "Custom branding",
        "All question types",
        "Advanced export options",
        "Priority email support"
      ],
      bgColor: "bg-[#D3FBF1]"
    }
  };

  const faqs = [
    {
      question: "What happens when I reach my response limit?",
      answer: "When you reach your plan's response limit, you'll be notified. Your survey will stop collecting new responses until you upgrade your plan or the next billing cycle begins. All existing responses are safely stored."
    },
    {
      question: "Can I switch between monthly and annual billing?",
      answer: "Yes! You can switch between monthly and annual billing at any time. When switching to annual, you'll save 20% and get 3 months free. Any unused time on your current plan will be prorated and credited to your new plan."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major credit cards (Visa, MasterCard, American Express) and PayPal. For enterprise plans, we can also accommodate bank transfers and purchase orders."
    },
    {
      question: "Is there a free trial for paid plans?",
      answer: "Yes! You can start with our Free plan to test our core features. When you're ready to upgrade, you can try any paid plan for 14 days with all features unlocked. No credit card required for the trial."
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Absolutely! You can cancel your subscription at any time. There are no long-term contracts or cancellation fees. If you cancel, you'll still have access to your plan until the end of your current billing period."
    },
    {
      question: "What's included in Priority Support?",
      answer: "Priority Support includes faster response times (within 4 hours during business hours), dedicated support specialists, and access to our priority support channel. Pro and Growth plans include this feature."
    }
  ];

  const renderPricingToggle = () => (
    <div className="flex justify-center mb-12 sm:mb-16 px-4">
      <div className="inline-flex items-center rounded-[20px] bg-white border border-[#E4E4E4] p-2 w-full sm:w-auto shadow-sm">
        <div className="flex items-center space-x-2 sm:space-x-4 w-full sm:w-auto">
          <ToggleButton 
            isActive={isAnnual}
            onClick={() => setIsAnnual(true)}
            label="Annual"
            sublabel="(3 month free)"
          />
          <ToggleButton 
            isActive={!isAnnual}
            onClick={() => setIsAnnual(false)}
            label="Monthly"
          />
        </div>
      </div>
    </div>
  );

  return (
    <PageWrapper
      openSignIn={openSignIn}
      isMobileMenuOpen={isMobileMenuOpen}
      setIsMobileMenuOpen={setIsMobileMenuOpen}
      bgColor="bg-white"
    >
      {/* Hero Section */}
      <div className="text-center mb-20">
        <h1 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl md:text-[62px] leading-tight sm:leading-[68.44px] tracking-[-1px] sm:tracking-[-2.489px] mb-4">
          Choose the Right Plan<br />
          For Your Needs
        </h1>
        <p className="font-['HK_Nova'] text-base sm:text-[18px] leading-normal sm:leading-[25px] font-normal text-gray-600 px-4">
          Start free and upgrade as you grow. No credit card required.
        </p>
      </div>

      {renderPricingToggle()}

      {/* Pricing Cards */}
      <div className="flex flex-col lg:flex-row justify-center gap-6 lg:gap-8 px-4 sm:px-8">
        <div className="w-full lg:w-auto">
          <PricingCard 
            {...pricingData.free}
            price="0"
            onClick={openSignIn}
          />
        </div>
        <div className="w-full lg:w-auto">
          <PricingCard 
            {...pricingData.pro}
            price={isAnnual ? prices.pro.annual : prices.pro.monthly}
            onClick={openSignIn}
          />
        </div>
        <div className="w-full lg:w-auto">
          <PricingCard 
            {...pricingData.growth}
            price={isAnnual ? prices.business.annual : prices.business.monthly}
            onClick={openSignIn}
          />
        </div>
      </div>

      {/* Feature Comparison */}
      <div className="mt-40 mb-40">
        <div className="text-center mb-20">
          <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-6 py-3 mb-8">
            <div className="w-2 h-2 rounded-full bg-black mr-3"></div>
            <span className="text-sm font-medium text-gray-700">Feature Comparison</span>
          </div>
          <h2 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl mb-6">
            Compare Plan Features
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Find the perfect plan for your needs
          </p>
        </div>

        {/* Comparison Table */}
        <div className="flex flex-col md:flex-row justify-center gap-8 px-4 sm:px-8">
          <div className="w-full max-w-[1400px] overflow-x-auto rounded-2xl border border-gray-100 bg-white shadow-sm">
            <table className="w-full">
              <thead>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <th className="py-6 px-8 text-left min-w-[200px] font-['HK_Nova'] text-lg">Features</th>
                  <th className="py-6 px-8 text-center font-['HK_Nova'] text-lg w-[220px]">Free</th>
                  <th className="py-6 px-8 text-center font-['HK_Nova'] text-lg w-[220px]">Pro</th>
                  <th className="py-6 px-8 text-center font-['HK_Nova'] text-lg w-[220px]">Growth</th>
                </tr>
              </thead>
              <tbody>
                {[
                  { feature: "Number of Surveys", free: "3", pro: "Unlimited", growth: "Unlimited" },
                  { feature: "Responses per Survey", free: "100", pro: "1,000", growth: "Unlimited" },
                  { feature: "Question Types", free: "Basic", pro: "All Types", growth: "All Types" },
                  { feature: "Custom Branding", free: "✕", pro: "✓", growth: "✓" },
                  { feature: "Export Options", free: "Basic", pro: "Advanced", growth: "Advanced" },
                  { feature: "Analytics", free: "Basic", pro: "Advanced", growth: "Advanced + AI" },
                  { feature: "Team Members", free: "1", pro: "3", growth: "Unlimited" },
                  { feature: "Priority Support", free: "✕", pro: "✓", growth: "✓" },
                ].map((row, index) => (
                  <tr key={index} className="border-b border-gray-100 hover:bg-gray-50/50 transition-colors">
                    <td className="py-6 px-8 font-medium text-gray-900">{row.feature}</td>
                    <td className="py-6 px-8 text-center text-gray-600">
                      <span className={row.free === "✓" ? "text-green-500 font-medium" : row.free === "✕" ? "text-gray-400" : ""}>
                        {row.free}
                      </span>
                    </td>
                    <td className="py-6 px-8 text-center text-gray-600">
                      <span className={row.pro === "✓" ? "text-green-500 font-medium" : row.pro === "✕" ? "text-gray-400" : ""}>
                        {row.pro}
                      </span>
                    </td>
                    <td className="py-6 px-8 text-center text-gray-600">
                      <span className={row.growth === "✓" ? "text-green-500 font-medium" : row.growth === "✕" ? "text-gray-400" : ""}>
                        {row.growth}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="mb-40">
        <div className="text-center mb-20">
          <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-6 py-3 mb-8">
            <div className="w-2 h-2 rounded-full bg-black mr-3"></div>
            <span className="text-sm font-medium text-gray-700">Testimonials</span>
          </div>
          <h2 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl mb-6">
            What Our Customers Say
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
          {[
            {
              quote: "PollBolt has transformed how we gather customer feedback. The interface is intuitive and the results are insightful.",
              author: "Sarah Johnson",
              role: "Product Manager",
              company: "TechCorp",
              bgColor: "bg-[#fffec0]"
            },
            {
              quote: "The best survey platform we've used. The analytics features are powerful and help us make data-driven decisions.",
              author: "Michael Chen",
              role: "Research Director",
              company: "DataInsights",
              bgColor: "bg-[#ffe8ce]"
            },
            {
              quote: "Easy to use, great features, and excellent support. PollBolt has everything we need for our surveys.",
              author: "Emily Brown",
              role: "Marketing Lead",
              company: "GrowthCo",
              bgColor: "bg-[#dce1fe]"
            }
          ].map((testimonial, index) => (
            <div key={index} className="bg-white p-10 rounded-2xl border border-gray-100 hover:shadow-lg transition-all duration-300">
              <div className={`mb-8 ${testimonial.bgColor} w-12 h-12 rounded-xl flex items-center justify-center`}>
                <svg className="w-6 h-6 text-gray-700" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                </svg>
              </div>
              <p className="text-gray-600 mb-8 leading-relaxed text-lg">{testimonial.quote}</p>
              <div>
                <div className="font-medium text-gray-900 text-lg mb-1">{testimonial.author}</div>
                <div className="text-gray-500">{testimonial.role}</div>
                <div className="text-gray-500">{testimonial.company}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Enterprise Section */}
      <div className="mb-40">
        <div className="bg-gradient-to-br from-[#dce1fe] to-white rounded-3xl p-16 sm:p-24 border border-[#dce1fe]/50 relative overflow-hidden">
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-[#dce1fe]/30 rounded-full blur-3xl -z-10"></div>
          <div className="max-w-4xl mx-auto text-center relative">
            <h2 className="font-['HK_Nova'] font-bold text-3xl sm:text-4xl mb-6">
              Need a Custom Solution?
            </h2>
            <p className="text-xl text-gray-600 mb-12">
              Get in touch with our team to discuss enterprise plans and custom requirements
            </p>
            <button 
              className="px-10 py-5 text-lg rounded-2xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova'] group relative overflow-hidden shadow-lg hover:shadow-xl"
              onClick={() => window.location.href = 'mailto:enterprise@pollbolt.com'}
            >
              <span className="relative z-10">Contact Enterprise Sales</span>
              <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></div>
              <span className="inline-block ml-2 relative z-10 group-hover:translate-x-1 transition-transform">→</span>
            </button>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="mt-32 max-w-4xl mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-['HK_Nova'] font-bold mb-16 text-center">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <FaqItem 
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>

        {/* Support CTA */}
        <div className="mt-16 text-center">
          <p className="text-gray-600 mb-4">
            Still have questions?
          </p>
          <a 
            href="mailto:support@pollbolt.com"
            className="text-black font-medium hover:text-gray-800 transition-colors"
          >
            Contact our support team →
          </a>
        </div>
      </div>
    </PageWrapper>
  );
};

const ToggleButton = ({ isActive, onClick, label, sublabel }) => (
  <div 
    className={`px-3 sm:px-4 py-2 sm:py-3 rounded-[16px] transition-colors duration-300 cursor-pointer font-medium flex-1 sm:flex-none text-center
    ${isActive ? 'text-black' : 'text-gray-400'}`}
    onClick={onClick}
  >
    <span className={`text-sm sm:text-base relative z-10 whitespace-nowrap ${
      isActive ? 'bg-[#FFFD63] -mx-3 sm:-mx-4 -my-2 sm:-my-3 px-3 sm:px-4 py-2 sm:py-3 rounded-[16px] block' : ''
    }`}>
      {label}
      {sublabel && <span className="hidden sm:inline text-sm"> {sublabel}</span>}
    </span>
  </div>
);

const FaqItem = React.memo(({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-2xl overflow-hidden">
      <button
        className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50 transition-colors"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-['HK_Nova'] font-medium text-lg">
          {question}
        </span>
        <span className={`transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}>
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M19 9L12 16L5 9" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>
      <div 
        className={`px-6 transition-all duration-200 ease-in-out ${
          isOpen ? 'py-4 opacity-100 border-t border-gray-200' : 'max-h-0 py-0 opacity-0'
        }`}
      >
        <p className="text-gray-600 leading-relaxed">
          {answer}
        </p>
      </div>
    </div>
  );
});

FaqItem.displayName = 'FaqItem';

export default PlansPage; 