import React from 'react';
import { 
  Zap, 
  TrendingUp, 
  Target,
  Users,
  LineChart,
  Briefcase,
  Calendar,
  GraduationCap,
  Box,
  TestTube,
  Heart,
  Building
} from 'lucide-react';

const LandingContent = ({ openSignIn }) => {
  const benefits = [
    {
      title: "Lightning Fast Setup",
      description: "Create and launch your survey in minutes. No technical skills required.",
      Icon: Zap,
      bgColor: "bg-[#fffec0]"
    },
    {
      title: "Higher Response Rates",
      description: "Engaging survey experience leads to better completion rates.",
      Icon: TrendingUp,
      bgColor: "bg-[#ffe8ce]"
    },
    {
      title: "Instant Insights",
      description: "Real-time analytics and AI-powered insights as responses come in.",
      Icon: Target,
      bgColor: "bg-[#dce1fe]"
    }
  ];

  const useCases = [
    { label: "Customer Feedback", Icon: Heart },
    { label: "Market Research", Icon: LineChart },
    { label: "Employee Surveys", Icon: Users },
    { label: "Event Planning", Icon: Calendar },
    { label: "Education & Training", Icon: GraduationCap },
    { label: "Product Development", Icon: Box },
    { label: "User Testing", Icon: TestTube },
    { label: "Enterprise Solutions", Icon: Building }
  ];

  return (
    <div className="pt-20">
      {/* Key Benefits Section */}
      <div className="mb-40">
        <div className="text-center mb-20">
          <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-6 py-3 mb-6">
            <div className="w-2 h-2 rounded-full bg-black mr-3"></div>
            <span className="text-sm font-medium text-gray-700">Key Benefits</span>
          </div>
          <h2 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl md:text-6xl mb-8">
            Why choose PollBolt?
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            Everything you need to create, distribute, and analyze surveys effectively
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 px-4">
          {benefits.map((benefit, index) => (
            <div 
              key={index} 
              className="bg-white rounded-2xl p-10 group hover:shadow-xl transition-all duration-500 border border-gray-100 hover:border-gray-200"
            >
              <div className={`mb-8 ${benefit.bgColor} w-16 h-16 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-500`}>
                <benefit.Icon className="w-8 h-8 text-gray-700" />
              </div>
              <h3 className="text-2xl font-bold mb-4 font-['HK_Nova'] group-hover:translate-x-1 transition-transform duration-300">
                {benefit.title}
              </h3>
              <p className="text-gray-600 leading-relaxed text-lg">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Use Cases Section */}
      <div className="mb-40">
        <div className="text-center mb-20">
          <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-6 py-3 mb-6">
            <div className="w-2 h-2 rounded-full bg-black mr-3"></div>
            <span className="text-sm font-medium text-gray-700">Use Cases</span>
          </div>
          <h2 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl md:text-6xl mb-8">
            Perfect for every need
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed">
            From quick polls to in-depth research, PollBolt adapts to your requirements
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 px-4">
          {useCases.map((useCase, index) => (
            <div 
              key={index}
              className="bg-white rounded-xl p-8 font-medium hover:shadow-lg transition-all duration-300 cursor-default group border border-gray-100 hover:border-gray-200"
            >
              <div className="flex items-center gap-4">
                <div className="bg-[#d3fbee] p-3 rounded-xl group-hover:scale-110 transition-transform duration-300">
                  <useCase.Icon className="w-6 h-6 text-gray-700" />
                </div>
                <span className="text-lg">{useCase.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Social Proof Section */}
      <div className="mb-40">
        <div className="bg-gradient-to-br from-white to-gray-50 rounded-3xl p-16 sm:p-24 border border-gray-100">
          <div className="text-center">
            <div className="inline-flex items-center bg-black/5 backdrop-blur-sm rounded-full px-6 py-3 mb-8">
              <div className="w-2 h-2 rounded-full bg-black mr-3"></div>
              <span className="text-sm font-medium text-gray-700">Our Customers</span>
            </div>
            <h2 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl mb-16">
              Trusted by innovative teams
            </h2>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-16 items-center justify-items-center opacity-70">
            {Array(6).fill('LOGO').map((_, index) => (
              <div key={index} className="h-12 w-40 bg-gray-200 rounded-lg hover:opacity-80 transition-opacity cursor-pointer"></div>
            ))}
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="mb-20">
        <div className="bg-gradient-to-br from-[#FFFD63]/20 to-white rounded-3xl p-16 sm:p-24 border border-[#FFFD63]/20">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="font-['HK_Nova'] font-bold text-4xl sm:text-5xl mb-6">
              Ready to get started?
            </h2>
            <p className="text-xl text-gray-600 mb-12">
              Join thousands of users who are already creating better surveys with PollBolt
            </p>
            <div className="flex flex-col sm:flex-row gap-6 justify-center items-center">
              <button 
                className="px-10 py-5 text-lg rounded-2xl bg-black text-white hover:bg-gray-800 transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto group relative overflow-hidden shadow-lg hover:shadow-xl"
                onClick={() => openSignIn(true)}
              >
                <span className="relative z-10">Start For Free</span>
                <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-black transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></div>
              </button>
              <button 
                className="px-10 py-5 text-lg rounded-2xl border-2 border-black hover:bg-black hover:text-white transition-all duration-300 font-['HK_Nova'] w-full sm:w-auto"
                onClick={() => window.location.href = '/plans'}
              >
                View Pricing
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingContent; 